<template>
  <header>
    <div class="header-body">
      <div class="logo-wrapper">
        <router-link to="/">
          <img class="logo" src="~@/assets/logo.svg" />
        </router-link>
      </div>
      <ul>
        <router-link to="/" :active="activePath === 'stake'">Staking</router-link>
        <router-link to="/maci" :active="activePath === 'maci'">MACI</router-link>
        <li class="hidden">DAOs</li>
        <li class="hidden">Governance</li>
      </ul>
      <Account />
    </div>
  </header>
</template>

<script>
import Account from './Account'

export default {
  name: 'Header',
  components: {
    Account,
  },
  computed: {
    activePath() {
      const path = this.$route.path
      switch (path) {
        case '/maci':
          return 'maci'
        default:
          return 'stake'
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/css/theme.styl'

header
  position sticky
  top 0
  width 100%
  height 70px
  background-color $white
  z-index 100
  box-shadow 0 0px 8px rgba($black, 0.1)
  .logo
    display block

.header-body
  padding 0 24px
  height 70px
  display flex
  align-items center
  justify-content space-between
  position relative
  z-index 101
  ul
    display flex
    align-items center
    gap 32px
    font-size 16px
    line-height 24px
    font-weight 600
  li
    cursor not-allowed
    opacity .5
  a
    &[active]
      color $orange

@media not all and (min-width 600px)
  .header-body
    padding 0 16px
    ul
      gap 12px
  .hidden
    display none
  .logo-wrapper
    width 32px
    overflow hidden
</style>
