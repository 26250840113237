<template>
  <div class="login">
    <h1>{{ title }}</h1>
    <Wallet @connect="onconnect" />
    <slot>
      <p class="notice">
        To learn more about Dora Grant DAO and $DORA tokenomics,
        <a href="https://dorahacks.io/blog/guides/vcdora/" target="_blank">
          <u>check out the guide</u> »
        </a>
      </p>
    </slot>
  </div>
</template>

<script>
import Wallet from '@/components/chain/EthereumAccount'

export default {
  name: 'Login',
  props: {
    title: {
      type: String,
      default: 'Connect your wallet to stake $DORA and access your own stats',
    },
  },
  components: {
    Wallet,
  },
  methods: {
    onconnect() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/css/theme.styl'

.login
  margin auto
  width 400px
  h1
    margin-bottom 48px
    padding 0 12px
    font-size 24px
    line-height 32px
    font-weight 600
    text-align center
    > b
      color $orange
      font-weight 600
.notice
  margin-top 48px
  padding 0 16px
  line-height 24px
  text-align center
  a
    color $orange
</style>
