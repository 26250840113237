<template>
  <section class="ds-block stake-submitted">
    <img src="~@/assets/submitted.svg" />
    <h1>Your transcation has been submitted successfully</h1>
    <p class="notice">
      You can check the status of transcation on block explorer:
      <a :href="explorerUrl" target="_blank">
        <u>{{ addrTxHash }}</u> »
      </a>
    </p>
    <div class="buttons">
      <router-link to="/mine" class="main-button" major>View Status</router-link>
      <div class="main-button" @click="$emit('continue')">Continue to Stake</div>
    </div>
  </section>
</template>

<script>
import { STRING_PROPS, hexFormatter } from '@/assets/utils'

export default {
  name: 'AfterStake',
  props: {
    txHash: STRING_PROPS,
  },
  computed: {
    addrTxHash() {
      return hexFormatter(this.txHash)
    },
    explorerUrl() {
      return 'https://etherscan.io/tx/' + this.txHash
    },
  },
}
</script>

<style lang="stylus">
@import '~@/css/theme.styl'

.stake-submitted
  display flex
  flex-direction column
  align-items center
  h1
    margin-top 8px
    width 368px
    font-size 24px
    line-height 32px
    font-weight 600
    color $orange
    text-align center
  .notice
    margin-top 16px
    width 368px
    line-height 24px
    text-align center
    a
      color $orange
  .buttons
    margin-top 48px
    display flex
    gap 16px
</style>
