export const parseTimestamp = (ts) => {
  const date = new Date(ts)
  const yyyy = date.getFullYear().toString()
  const mm = (date.getMonth() + 1).toString().padStart(2, '0')
  const dd = date.getDate().toString().padStart(2, '0')
  const time = date.toTimeString().slice(0, 8)

  let tz = -date.getTimezoneOffset() / 60
  if (tz === 0) {
    tz = ''
  } else if (tz > 0) {
    tz = '+' + tz.toString()
  } else {
    tz = tz.toString()
  }

  return [yyyy, '/', mm, '/', dd, ' ', time, ' UTC', tz].join('')
}

export const parseDuration = (dts) => {
  const seconds = dts / 1000

  const mins = Math.ceil(seconds / 60) % 60
  const hours = Math.floor(seconds / 3600) % 24
  const days = Math.floor(seconds / 86400)

  const m = mins.toString() + ' min' + (mins !== 1 ? 's' : '')
  const h = hours.toString() + ' hour' + (hours !== 1 ? 's' : '')
  const d = days.toString() + ' day' + (days !== 1 ? 's' : '')

  const segments = []
  if (days !== 0) {
    segments.push(d)
  }
  if (hours !== 0) {
    segments.push(h)
  }
  if (mins !== 0) {
    segments.push(m)
  }

  return segments.slice(0, 2).join(' ')
}
