<template>
  <footer>
    <div class="footer-hr">
      <div class="main-width">
        <span>
          <i />
          <i />
        </span>
      </div>
    </div>
    <div class="footer-con main-width">
      <div class="logo">
        <router-link to="/">
          <img src="~@/assets/logo.svg" />
        </router-link>
        <p>
          Explore the world of innovation at DoraHacks, BUIDLs, continuous funding, grants,
          bounties, and hackathons.
        </p>
        <div class="join-container">
          <div class="join-area">
            <div class="title">
              <img src="./icons/discord.svg" alt="discord" /> Join DoraHacks Discord
              <img class="hearts" src="./icons/hearts.png" alt="hearts" />
            </div>
            <div>Click the link to join DoraHacks Discord channel:</div>
            <a class="link" href="https://discord.gg/gKT5DsWwQ5" target="_blank">
              <span>https://discord.gg/gKT5DsWwQ5</span> »
            </a>
          </div>
          <div class="join-area tg-area">
            <div>
              <div class="title">
                <img src="./icons/planet.svg" alt="planet" />Join DoraHacks Multichain Voter
                Community
              </div>
              <div>
                Click the link or scan the QR code to join the DoraHacks multichain voter community
                on Telegram:
              </div>
              <a class="link" href="https://t.me/hackerlinkvoter" target="_blank">
                <span>t.me/hackerlinkvoter</span> »
              </a>
            </div>
            <img class="tg-qrcode" src="./icons/tg-qrcode.png" alt="telegram QR Code" />
          </div>
        </div>
      </div>
      <div class="footer-links">
        <div>
          <p>About Us</p>
          <a v-for="(item, i) in aboutUs" :key="i" :href="item[1]" target="_blank">
            {{ item[0] }}
          </a>
        </div>
        <div>
          <p>Products</p>
          <a v-for="(item, i) in products" :key="i" :href="item[1]" target="_blank">
            {{ item[0] }}
          </a>
        </div>
        <div>
          <p>Communities</p>
          <a v-for="(item, i) in communities" :key="i" :href="item[1]" target="_blank">
            {{ item[0] }}
          </a>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="main-width">
        <p>Copyright © 2022 DoraHacks. All rights reserved.</p>
        <div class="link">
          <a v-for="(item, i) in footer" :key="i" :href="item[1]" target="_blank">
            {{ item[0] }}
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
const aboutUs = [
  // ['Blog', '/'],
  ['Terms', 'https://dorahacks.io/protocol/Term-of-Use-Agreement.pdf'],
  ['Privacy', 'https://dorahacks.io/protocol/Privacy-Policy.pdf'],
  // ['Announcements', '/'],
  // ['News', '/'],
  ['Jobs', 'https://www.linkedin.com/company/dorahacks/jobs'],
]
const products = [
  ['BUIDL', 'https://dorahacks.io/buidl'],
  ['Grant', 'https://dorahacks.io/grant'],
  // ['Continuous Funding', '/'],
  ['Hackathon', 'https://dorahacks.io/hackathon'],
  ['Dora Factory', 'https://dorafactory.org/'],
  ['DAOrayaki', 'http://daorayaki.org/'],
  ['DoraID', 'https://dorafactory.github.io/DoraID/'],
  ['Hackathon DAO', 'https://dorahacksglobal.github.io/Hackathon-Playbook/dao/'],
]
const communities = [
  ['Twitter', 'https://twitter.com/DoraHacks'],
  ['YouTube', 'https://www.youtube.com/c/DoraHacksGlobal'],
  // ['Discord', '/'],
  // ['Telegram', '/'],
  ['Facebook', 'https://www.facebook.com/DoraHacksGlobalHack'],
  ['LinkedIn', 'https://www.linkedin.com/company/dorahacks/'],
  ['Medium', 'https://hidorahacks.medium.com/'],
]
const footer = [
  ['Privacy policy', 'https://dorahacks.io/protocol/Privacy-Policy.pdf'],
  // ['CA notice', '/'],
  // ['Community guidelines', '/'],
  ['Terms of service', 'https://dorahacks.io/protocol/Term-of-Use-Agreement.pdf'],
]

export default {
  name: 'Footer',
  data() {
    const needShowICPCode = process.client ? /\.net$/.test(location.host) : false
    return {
      needShowICPCode,

      aboutUs,
      products,
      communities,
      footer,
    }
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/css/theme.styl'

footer
  position relative
  .main-width
    max-width unset
.footer-hr
  position absolute
  left 0
  top 0
  width 100%
  height 1px
  background-color $black + 85%
  transition opacity .2s
  span
    width 24px
    height 1px
    background-color $white
    display flex
    justify-content center
    align-items center
  i
    margin 0 1px
    width 4px
    height 10px
    background-color $black + 85%
    transform skewX(-20deg)

.footer-con
  padding-top 60px
  padding-bottom 40px
  display flex
  justify-content space-between
  overflow hidden
  .logo
    flex 0 1 500px
    img
      display block
    p
      margin-top 16px
      font-size 14px
      opacity .5
  .join-container
    padding 12px 0
  .join-area
    padding 24px 0
    font-size 12px
    line-height 18px
    .link
      color $orange
      > span
        text-decoration underline
    .title
      padding-bottom 10px
      display flex
      align-items center
      color $black
      font-size 16px
      line-height 21px
      font-weight 700
      > img
        padding-right 8px
    .hearts
      transform scale(0.6) translate(-30px, -25px)
  .tg-area
    border-top 0.5px solid $black + 80%
    display flex
  .tg-qrcode
    margin -10px 10px 0
    width 107px
    height 107px

.footer-links
  margin-left 60px
  flex 0 0 auto
  display flex
  font-size 14px
  > div
    flex 0 0 auto
  > div:not(:first-child)
    margin-left 120px
  p
    font-size 16px
    font-weight 600
  a
    margin-top 18px
    display block

.footer-bottom
  background-color rgba(#e1e1e1, .1)
  font-size 14px
  .main-width
    height 36px
    line-height 26px
    display flex
    justify-content space-between
    align-items center
    opacity .3
  .link
    display flex
    align-items center
  a
    text-decoration underline
    &:not(:last-child)
      margin-right 48px

@media screen and (max-width 750px)
  .footer-con
    flex-direction column
    align-items flex-start
    .logo
      flex-basis auto

  .wechat-qr-codes
    margin 14px auto 0
    justify-content space-around
    > img
      margin 0
      width 260px
      height 260px

  .footer-links
    margin 36px 0 24px
    width 100%
    justify-content space-between

  .footer-bottom
    .main-width
      padding-top 4px
      padding-bottom 4px
      height auto
      flex-direction column-reverse
      align-items flex-start
</style>
