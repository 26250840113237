<template>
  <div class="main-modal-container">
    <div
      v-show="!completelyHide"
      ref="content"
      :style="{ maxWidth: grids * 96 + 16 + 'px' }"
      class="main-modal-content"
    >
      <div class="close-button" @click="beforeClose"></div>
      <div v-if="returnable" class="return-button" @click="$emit('continue')"></div>
      <div class="scroll-body">
        <slot />
        <div class="default-layer">
          <h1 v-if="title">{{ title }}</h1>
          <p v-if="content">{{ content }}</p>
          <div v-if="buttons.length" class="buttons">
            <div
              v-for="(b, i) in buttons"
              :key="i"
              :major="b.major"
              class="main-button"
              @click="onclick(b)"
            >
              {{ b.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ARRAY_PROPS, STRING_PROPS, BOOLEAN_PROPS } from '@/assets/utils'

export default {
  name: 'ModalContainer',
  props: {
    title: STRING_PROPS,
    content: STRING_PROPS,
    buttons: ARRAY_PROPS,
    grids: {
      type: Number,
      default: 8,
    },
    returnable: BOOLEAN_PROPS,
  },
  data() {
    return {
      hide: false,
      completelyHide: false,
    }
  },
  watch: {
    '$route.path'() {
      this.$emit('close')
    },
    hide(v) {
      if (v) {
        // hide
        const content = this.$refs.content
        if (!(content instanceof HTMLElement)) return
        content.style.transition = 'opacity .4s, transform .4s'
        content.style.opacity = '0'
        content.style.transform = 'translateY(-32px)'
        setTimeout(() => {
          this.completelyHide = this.hide
        }, 500)
      } else {
        // show
        const content = this.$refs.content
        if (!(content instanceof HTMLElement)) return
        content.style.animation = 'unset'
        content.style.transition = 'opacity .4s'
        content.style.opacity = '1'
        content.style.transform = 'translateY(0)'
        this.completelyHide = false
      }
    },
  },
  methods: {
    onclick(buttonConfig) {
      if (buttonConfig.onclick) {
        buttonConfig.onclick(this)
      } else {
        this.$emit('close')
      }
    },
    getleavingWarning() {
      for (const vm of this.$children) {
        if (vm.leavingWarning) {
          return vm.leavingWarning
        }
      }
      return ''
    },
    beforeClose() {
      const warning = this.getleavingWarning()
      if (warning && !window.confirm(warning)) {
        return
      }
      this.$emit('close')
    },
  },
}
</script>

<style lang="stylus">
@import '~@/css/theme.styl'

#modal-container
  display none
  position fixed
  z-index 500
  width 100%
  height 100%
  top 0
  left 0
  background-color rgba($white, .8)
  backdrop-filter blur(20px)
  animation fadeIn .2s

.main-modal-container
  position fixed
  z-index 501
  padding 128px 16px 0
  width 100%
  height 100%
  top 0
  left 0
  display flex
  justify-content center
  align-items flex-end

  --padding-v 64px
  --padding-h 96px

.main-modal-content
  max-width 784px
  width 100%
  height 100%
  border-radius 4px 4px 0 0
  background-color $white
  filter drop-shadow(0 0 2px rgba($black, .125)) drop-shadow(0 0 4px rgba($black, 0.1))
  animation slideIn .3s
  position relative
  .close-button
    position absolute
    top 8px
    right 8px
    width 32px
    height 32px
    cursor pointer
    z-index 2
    &:before, &:after
      content ''
      position absolute
      top 15px
      left 8px
      width 16px
      height 2px
      border-radius 1px
      background-color $orange
    &:before
      transform rotate(45deg)
    &:after
      transform rotate(-45deg)
  .return-button
    position absolute
    top 8px
    left 8px
    width 32px
    height 32px
    background no-repeat url('~@/assets/icons/return_32.svg') center / cover
    cursor pointer
    z-index 2

  .scroll-body
    padding var(--padding-v, 64px) var(--padding-h, 16px) 0
    height 100%
    overflow-y scroll
    &::-webkit-scrollbar
      display none
  .default-layer
    padding-bottom var(--padding-v, 64px)
    width 100%
    > p
      margin 24px 0
      padding 0 16px
      font-size 16px
      line-height 24px

  h1
    margin-bottom 48px
    padding 0 16px
    font-size 24px
    line-height 32px
    font-weight 600
    > b
      color $orange
      font-weight 600

  // COMMON STYLE
  .m-hr
    margin 24px -16px
    margin 24px calc(0px - var(--padding-h, 16px))
    height 1px
    background-color $black + 90%
  .buttons, .m-buttons
    margin 0 -16px -16px 0
    height 64px
    display flex
    > div
      margin 0 16px 16px 0
  .m-notice
    display flex
    justify-content space-between
    > img
      margin-right 16px
      flex 0 0 48px
      height 48px
      width 48px
    > div:last-child
      flex 1 1 auto
      padding 6px 0
      font-size 12px
      line-height 18px
    hr
      margin 24px 0
      height 1px
      background-color $black + 90%
    .o
      color $orange
    .b
      font-weight 600
    .u
      text-decoration underline
  .m-user-agreement
    margin-top -6px
    font-weight 600
    display flex
    align-items center
    > i
      margin-right 8px
      flex 0 0 16px
      width 16px
      height 16px
      border-radius 2px
      border solid 1px $black + 80%
      position relative
      transition all .12s
      cursor pointer
      &:before
        content ''
        position absolute
        top 40%
        left 50%
        width 7px
        height 3px
        border-bottom solid 2px $white
        border-left solid 2px $white
        transform translate3d(-50%, -50%, 0) rotate(-45deg) scale(0)
        transition all .2s
      &[selected]
        background-color $orange
        border-color $orange
        &:before
          transform translate3d(-50%, -50%, 0) rotate(-45deg)
    u
      color $orange
      text-decoration underline
      cursor pointer

@keyframes fadeIn
  from
    opacity 0
  to
    opacity 1
@keyframes slideIn
  from
    transform translateY(100px)
  to
    transform translateY(0)

@media screen and (max-width 750px)
  .main-modal-container
    padding 8px 8px 0
    --padding-h 16px
  .main-modal-content:before
    content ''
    position absolute
    top 0
    left 0
    width 100%
    height 64px
    background-image linear-gradient(to bottom, $white, rgba($white, .9), rgba($white, 0))
    z-index 1
</style>
