<template>
  <div class="dora-staking-body main-width">
    <div v-if="account" class="ds-router">
      <router-link to="/mine" :focus="path === '/mine'">Your Stats</router-link>
      <router-link to="/" :focus="path === '/'">Overall Stats</router-link>
      <router-link to="/stake" :focus="path === '/stake'">Stake $DORA</router-link>
    </div>
    <div v-else class="ds-title">
      <h1>DORA Governance Stats</h1>
      <span></span>
    </div>
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['account']),
    path() {
      return this.$route.path
    },
  },
}
</script>
