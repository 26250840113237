<template>
  <div class="user-account-container">
    <div v-if="account" class="user-account" :focus="showUserMenu" @click="openUserMenu">
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.3 13.6c.417 0 .787-.162 1.113-.487.325-.325.487-.705.487-1.138 0-.417-.162-.783-.487-1.1-.326-.317-.696-.475-1.113-.475-.417 0-.788.158-1.113.475-.324.317-.487.683-.487 1.1 0 .433.163.813.488 1.138.324.325.695.487 1.112.487zM4.5 18.175V19.5v-15V18.175zM4.5 21c-.383 0-.73-.15-1.038-.45C3.155 20.25 3 19.9 3 19.5v-15c0-.383.154-.73.462-1.038C3.772 3.155 4.117 3 4.5 3h15c.4 0 .75.154 1.05.462.3.309.45.655.45 1.038v3.35h-1.5V4.5h-15v15h15v-3.325H21V19.5c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-15zm8.95-4.325c-.567 0-1.017-.167-1.35-.5-.333-.333-.5-.775-.5-1.325V9.175c0-.567.167-1.013.5-1.338.333-.324.783-.487 1.35-.487h6.75c.567 0 1.017.163 1.35.488.333.324.5.77.5 1.337v5.675c0 .55-.167.992-.5 1.325-.333.333-.783.5-1.35.5h-6.75zm7.1-1.5V8.85H13.1v6.325h7.45z"
        />
      </svg>
      <span>{{ abbrAccount }}</span>
      <i></i>
    </div>
    <div v-else @click="toConnectWallet" class="main-button" major>Connect Wallet</div>

    <transition name="options">
      <WalletBubble v-if="showUserMenu" class="bubble" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { hexFormatter } from '@/assets/utils'
import LoginModal from '@/components/modal/Login'
import WalletBubble from './WalletBubble'

export default {
  name: 'Account',
  components: {
    WalletBubble,
  },
  data() {
    return {
      showUserMenu: false,
    }
  },
  computed: {
    ...mapGetters(['account']),
    abbrAccount() {
      return hexFormatter(this.account)
    },
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.closeUserMenu, true)
  },
  methods: {
    openUserMenu() {
      this.showUserMenu = true
      document.body.addEventListener('click', this.closeUserMenu, true)
    },
    closeUserMenu() {
      setTimeout(() => {
        this.showUserMenu = false
        document.body.removeEventListener('click', this.closeUserMenu, true)
      })
    },
    toConnectWallet() {
      this.$modal(<LoginModal />)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/css/theme.styl'

.user-account-container
  flex 0 0 160px
  position relative
  display flex
  justify-content flex-end
  .bubble
    position absolute
    top 32px
    right 0
.user-account
  display flex
  align-items center
  line-height 24px
  cursor pointer
  fill $black
  transition fill .2s, color .2s
  > svg
    margin-right 8px
    fill inherit
  > span
    white-space nowrap
    font-weight 600
  > i
    margin 8px 0 8px 8px
    width 8px
    height 8px
    position relative
    border-color inherit
    transition transform .2s
    &:after
      content ''
      position absolute
      top 2px
      left 0
      border-top 5px solid $black
      border-top-color inherit
      border-left 4px solid transparent
      border-right 4px solid transparent

.user-account[focus]
  color $orange
  fill $orange
  > i
    transform rotate(180deg)

@media (any-hover hover)
  .user-account:hover
    color $orange
    fill $orange
</style>
