import { debounce } from '@/assets/utils'

export default {
  model: {
    prop: 'value',
    event: 'update:value',
  },
  data() {
    return {
      type: 'unkonw-input',
      id: '',
      error: '',
      holding: false,

      inputValue: undefined,
    }
  },
  mounted() {
    const id = Math.round(Math.random() * 1e12).toString(16)
    this.id = this.type + '-' + id
  },
  methods: {
    isEmpty(value) {
      return !value
    },
    realTimeSync: debounce(function () {
      this.$emit('update:value', this.inputValue)
    }, 100),
    beforeSync() {
      this.$warn('[components/ui/form] The method beforeSync() is now renamed sync()')
      // backward compatible
      return this.sync()
    },
    async sync() {
      const value = this.inputValue
      const c = this.config
      if (c.necessary && this.isEmpty(value)) {
        // It no longer displays an error message when there is no input by default,
        // unless it is clearly specified in `config.verify`
        this.error = ''
        try {
          this.error = c.verify(value)
        } catch {
          // Nothing
        }
      } else if (typeof c.verify === 'function') {
        const verified = c.verify(value)
        if (verified instanceof Promise) {
          this.holding = true
          await verified
            .then((err) => {
              this.error = err
            })
            .catch((err) => {
              this.error = err.message
            })
            .finally(() => {
              this.holding = false
            })
        } else {
          this.error = verified
        }
      } else {
        this.error = ''
      }
      this.$emit('update:value', this.inputValue)
    },
  },
}
