<template>
  <div style="margin: -14px"></div>
</template>

<script>
import * as echarts from 'echarts/core'
import { TooltipComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { ARRAY_PROPS, STRING_PROPS, amountFormatter, hexFormatter } from '@/assets/utils'

echarts.use([TooltipComponent, PieChart, CanvasRenderer])

const colors = [
  '#FF681C',
  '#FE711A',
  '#FD7919',
  '#FC8119',
  '#FA881A',
  '#F9901C',
  '#F7971F',
  '#F69E23',
  '#F4A427',
  '#F2AB2D',
  '#F1B132',
  '#EFB739',
  '#EDBD3F',
  '#EBC346',
  '#EAC94D',
  '#E8CE54',
  '#E7D45C',
  '#E5D964',
  '#E4DF6C',
  '#E2E474',
  '#E1E97C',
  '#E0EE85',
  '#DFF38E',
  '#DEF896',
  '#DEFD9F',
  '#D9FEA3',
  '#D1FBA3',
  '#C8F8A4',
  '#BFF5A4',
  '#B7F2A4',
  '#AFEFA5',
  '#A6ECA5',
  '#9EE9A6',
  '#96E6A7',
  '#8EE3A7',
  '#86E0A8',
  '#7EDCA9',
  '#76D9AA',
  '#6ED6AA',
  '#66D2AB',
  '#5ECFAC',
  '#56CBAC',
  '#4EC8AD',
  '#46C4AD',
  '#3EC0AE',
  '#35BDAE',
  '#2CB9AE',
  '#22B5AE',
  '#16B2AE',
  '#04AEAE',
  // rest addresses
  '#73738C',
]

const color = (n) => {
  const d = Math.min(n, 50) || 1
  const result = []
  for (let i = 0; i < n; i++) {
    result.push(colors[Math.floor((50 / d) * i)])
  }
  return result
}

const genOption = (_echartsInstance, data, dataType) => {
  const total = data.reduce((s, item) => s + item[0], 0)
  return {
    grid: {
      left: 0,
      bottom: 0,
      top: 0,
      right: 0,
    },
    tooltip: {
      trigger: 'item',
      position: (_point, _params, _dom, rect) => {
        return [rect.x + rect.width / 2, rect.y + rect.height / 2]
      },
      formatter: (params) => {
        const [value, name] = params.value
        return [
          '<div>',
          `<p>Address: <b>${name === 'rest' ? 'The rest addresses' : hexFormatter(name)}</b></p>`,
          `<p>${dataType}: <b>${amountFormatter(value)}</b></p>`,
          `<p>Relative weights: <b>${amountFormatter((value / total) * 100)}</b>%</p>`,
          '</div>',
        ].join('')
      },
      backgroundColor: '#0e0e11',
      borderWidth: 0,
      padding: [4, 8],
      textStyle: {
        color: '#fff',
        fontFamily: 'system-ui',
        fontSize: 12,
        lineHeight: 18,
      },
    },
    series: [
      {
        type: 'pie',
        data,
        radius: [105.6, 264],
        labelLine: {
          show: false,
        },
        emphasis: {
          scale: true,
          scaleSize: 13,
          // focus: 'self',
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
          },
        },
        blur: {
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 10,
          },
        },
        animationDuration: 400,
      },
    ],
    color: color(data.length),
  }
}

export default {
  name: 'Chart',
  props: {
    width: {
      type: Number,
      default: 556,
    },
    height: {
      type: Number,
      default: 556,
    },
    data: ARRAY_PROPS,
    dataType: STRING_PROPS,
  },
  data() {
    return {
      myChart: null,
    }
  },
  watch: {
    data() {
      this.myChart.setOption(genOption(this.myChart, this.data, this.dataType))
    },
  },
  mounted() {
    const myChart = echarts.init(this.$el, undefined, {
      width: this.width,
      height: this.height,
    })
    if (this.data.length) {
      myChart.setOption(genOption(myChart, this.data, this.dataType))
    }
    this.myChart = myChart
  },
  beforeDestroy() {
    if (this.myChart) {
      this.myChart.dispose()
    }
  },
}
</script>
