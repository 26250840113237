<template>
  <StakeHome>
    <div>
      <ul class="ds-stats">
        <li>
          <b>{{ locked.toFixed(2) }}</b>
          <span>Your $DORA locked</span>
        </li>
        <li>
          <b>{{ balance.toFixed(2) }}</b>
          <span>Your vcDORA</span>
        </li>
        <li>
          <b>{{ proportion }}<small>%</small></b>
          <span>Your % of power</span>
        </li>
      </ul>

      <section class="ds-graph">
        <h2>Your governance power (vcDORA)</h2>
        <Chart :data="data" />
      </section>

      <section class="ds-staking">
        <h2>Your staking activities</h2>
        <Table :data="userStakingHistory" />
      </section>
    </div>
  </StakeHome>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import StakeHome from '@/components/StakeHome'
import Chart from '@/components/Chart'
import Table from '@/components/UserTable'

export default {
  name: 'Mine',
  components: {
    StakeHome,
    Chart,
    Table,
  },
  computed: {
    ...mapState(['loading', 'locked', 'lockEnd', 'balance']),
    ...mapGetters(['account', 'proportion', 'userStakingHistory']),
    data() {
      if (this.loading) return []
      const now = Date.now()
      const day = 86400000
      const end = this.lockEnd
      if (end < now) {
        return [
          [now, 0],
          [now + 208 * day, 0],
        ]
      }
      const slope = this.locked / (208 * 7 * day)
      const data = []
      for (let t = Math.floor(now / day) * day; t <= end; t += day) {
        data.push([t, slope * (end - t)])
      }
      return data
    },
  },
}
</script>
