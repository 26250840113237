<template>
  <div id="app">
    <Nav />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import EthereumWallet from '@/components/chain/libs/EthereumWallet'

import Nav from '@/components/layouts/Nav'
import Footer from '@/components/layouts/Footer'

import config from '@/config.json'

export default {
  name: 'App',
  components: {
    Nav,
    Footer,
  },
  computed: {
    ...mapGetters(['account']),
    path() {
      return this.$route.path
    },
  },
  created() {
    this.$store.dispatch('updateStats')
    if (localStorage.getItem('vcd:wallet') === 'metamask') {
      this.connect()
    }
  },
  watch: {
    '$route.path'() {
      window.scrollTo({ top: 0 })
    },
    account(newAddr, oldAddr) {
      if (!newAddr) {
        this.$router.push('/')
      } else if (!oldAddr && this.$route.path === '/') {
        this.$router.push('/mine')
      }
      this.$store.dispatch('updateAllowance')
      this.$store.dispatch('updateBalance')
    },
  },
  methods: {
    ...mapMutations(['setWallet', 'setChain', 'setAccount']),
    async connect() {
      const Wallet = EthereumWallet.metamask

      const payload = {
        chainId: config.chainId,
        setChain: this.setChain,
        setAccount: this.setAccount,
      }
      await Wallet.connect(payload)
        .then((wallet) => {
          this.setWallet(wallet)
          return true
        })
        .catch(() => false)

      if (!this.account) {
        this.$router.push('/')
      }
    },
  },
}
</script>
