<template>
  <div>
    <h1>Link wallet from mobile device?</h1>

    <div class="m-notice">
      <img src="~@/assets/icons/alert_48.svg" />
      <div>
        Please revisit this page in built-in browser of the corresponding wallet APP.
        <br />
        Then try connecting the wallet again.
      </div>
    </div>

    <img class="link-wallet-help" :src="$cdn('/assets/link-wallet-help.jpg')" />
  </div>
</template>

<script>
export default {
  name: 'LinkWalletHelp',
}
</script>

<style lang="stylus" scoped>
.link-wallet-help
  display block
  margin 32px auto 0
  max-width 80%
</style>
