<template>
  <div class="dora-staking-body main-width">
    <section v-if="!account" class="ds-block">
      <Login title="Connect your wallet to sign up or reset key for Anonymous MACI">
        <p class="notice">
          <!-- To learn more about Anonymous MACI signup and voting,
          <a href="/" target="_blank"> <u>check out the guide</u> » </a> -->
        </p>
      </Login>
    </section>

    <section
      v-else-if="stage === 'signup-success' && focusedGrant"
      class="ds-block stake-submitted"
    >
      <img src="~@/assets/submitted.svg" />
      <h1>
        {{
          reseted
            ? 'You have reset your key successfully'
            : 'You have signed up for Anonymous MACI successfully'
        }}
      </h1>
      <p class="notice">
        Explore
        <a :href="`https://dorahacks.io/grant/${focusedGrant.uname}`" target="_blank">
          <u>{{ focusedGrant.title }}</u> »
        </a>
        to vote for your favorite projects
      </p>
      <div class="buttons">
        <div class="main-button" major @click="reset(focusedGrant)">Reset Key</div>
        <div class="main-button" @click="back">Explore Grants</div>
      </div>
    </section>

    <section v-else-if="stage === 'signup' && focusedGrant" class="ds-block modal-content">
      <h1>Sign up for {{ focusedGrant.title }}</h1>

      <div class="m-notice">
        <img src="~@/assets/icons/alert_48.svg" />
        <div>
          The key you get below will be your login method for the Anonymous MACI voting.
          <span class="b">
            Please backup your key securely. There is no method to recovery the key.
          </span>
          <!-- To learn more information,
          <a href="/" class="o"><u>check out the guide</u> »</a> -->
        </div>
      </div>

      <!-- <div class="ui-form-item">
        <label>Your vcDORA</label>
        <div class="input-box-disabled">{{ balance.toFixed(2) }}</div>
      </div> -->

      <div class="ui-form-item">
        <label class="mo-hidden" necessary>
          Keep this key in a secure place. Downloading is a suggested way to keep the key.
        </label>
        <label class="pc-hidden" necessary>Copy and keep this key in a secure place.</label>
        <textarea :value="toDownloadKey" rows="6" disabled class="input-box input-textarea" />
        <div class="small-buttons">
          <a
            class="mo-hidden"
            :href="toDownloadUrl"
            :download="toDownloadName"
            @click="downloaded = true"
          >
            <span v-if="downloaded">✅</span> <u>Download</u>
          </a>
          <p @click="copy"><span v-if="copied">✅</span> <u>Copy</u></p>
        </div>
      </div>

      <hr class="m-hr" />

      <div class="buttons">
        <div
          class="main-button pc-hidden"
          major
          :disabled="loading"
          @click="!loading && confirmSignup()"
        >
          {{ loading ? 'Waiting...' : 'Sign Up' }}
        </div>
        <div
          class="main-button mo-hidden"
          major
          :disabled="loading"
          @click="!loading && confirmSignup()"
        >
          {{ loading ? 'Waiting...' : 'Sign Up & Download Key' }}
        </div>
        <div class="main-button" @click="back">Back</div>
      </div>
    </section>

    <section v-else-if="stage === 'reset' && focusedGrant" class="ds-block modal-content">
      <h1>Reset key for {{ focusedGrant.title }}</h1>

      <div class="m-notice">
        <img src="~@/assets/icons/alert_48.svg" />
        <div>
          MACI key reset requests will be processed <span class="b">everyday at 2 AM&PM, UTC</span>.
          If you request to reset now, come again to get a new key after
          <span class="b">{{ nextUpdateTime }}</span
          >.
        </div>
      </div>

      <div class="m-notice">
        <img src="~@/assets/icons/alert_48.svg" />
        <div>
          To make your MACI voting experience anonymous, you should reset the MACI key first and use
          an updated key to vote.
          <span class="b">
            (Keep your MACI key in a secure place because there is no recovery method.)
          </span>
          <!-- To learn more information,
          <a href="/" class="o"><u>check out the guide</u> »</a> -->
        </div>
      </div>

      <div class="ui-form-item">
        <label necessary>
          Enter your current key,
          <span class="upload-file" @click="upload">
            or click here to upload your keystore file.
          </span>
        </label>
        <textarea
          v-model="inputKey"
          rows="6"
          placeholder="Your old key"
          class="input-box input-textarea"
          :disabled="readyToAddNewKey"
        />
      </div>

      <div v-if="readyToAddNewKey" class="ui-form-item">
        <label necessary> Please save the following <b>new key</b> securely </label>
        <textarea :value="toDownloadKey" rows="6" disabled class="input-box input-textarea" />
        <div class="small-buttons">
          <a
            class="mo-hidden"
            :href="toDownloadUrl"
            :download="toDownloadName"
            @click="downloaded = true"
          >
            <span v-if="downloaded">✅</span> <u>Download</u>
          </a>
          <p @click="copy"><span v-if="copied">✅</span> <u>Copy</u></p>
        </div>
      </div>

      <hr class="m-hr" />

      <div class="buttons">
        <div class="main-button" :disabled="loading" major @click="!loading && confirmReset()">
          {{
            loading
              ? 'Waiting...'
              : readyToAddNewKey
              ? 'Confirm to Reset & Download Key'
              : 'Request to Reset Key'
          }}
        </div>
        <div class="main-button" @click="back">Back</div>
      </div>

      <div v-if="proofing" class="proofing-wrapper">
        <div>
          <p class="title">Key resetting is processing</p>
          <div class="progress-bar"><span></span></div>
          <img width="64" src="~@/assets/icons/alert_48.svg" />
          <p>
            Please do not close the current browser tab or window, before the process is completed.
          </p>
          <p>
            Depending on your internet speed and device capabilities, this may take anywhere from 30
            seconds to several minutes.
          </p>
        </div>
      </div>
    </section>

    <section v-else-if="stage === 'reset-success' && focusedGrant" class="ds-block stake-submitted">
      <img src="~@/assets/submitted.svg" />
      <h1>
        {{
          reseted
            ? 'Please wait for the next update of key set'
            : 'Your request of resetting key have been submitted successfully'
        }}
      </h1>
      <p class="notice">
        Your request of resetting key have been submitted successfully Please come back to get your
        new key after <b>{{ nextUpdateTime }}</b
        >.
        <br />
        <br />
        <!-- You can find more information
        <a href="/" target="_blank"><u>from the guide</u> » </a> -->
      </p>
      <div class="buttons">
        <div class="main-button" major @click="back">Explore Grants</div>
      </div>
    </section>

    <ul v-else class="grants-wrapper">
      <li v-for="(item, i) in grants" :key="i" class="grant-item">
        <img :src="item.banner" />
        <div>
          <p class="title">{{ item.title }}</p>
          <p class="info">
            <span>Key set update frequency</span>
            <span>{{ item.ended ? '--' : '12 hours' }}</span>
          </p>
          <p class="info">
            <span>Next update time</span>
            <span>{{ item.ended ? '--' : nextUpdateTime }}</span>
          </p>
          <div class="buttons" v-if="item.ended">
            <div class="main-button" disabled>Ended</div>
          </div>
          <div class="buttons" v-else>
            <div class="main-button" major @click="signup(item)">Sign Up</div>
            <div class="main-button" @click="reset(item)">Reset Key</div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Web3 from 'web3'
import { poseidon } from 'circom'
import { mapGetters, mapState } from 'vuex'
import Login from '@/components/modal/Login'
import MACI from '@/assets/utils/maci'
import { switchChain } from '@/components/chain/libs/EthereumWallet'

// async function getDoraGrantProofSign(contract, round, address) {
//   return fetch('https://dorahacks.io/node-api/doragrant/sign/', {
//     method: 'POST',
//     mode: 'cors',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ contract, round, address }),
//   })
//     .then((res) => {
//       return res.json()
//     })
//     .catch(() => ({
//       signature: '',
//       balance: '0',
//     }))
// }

const maci = MACI()

// const testDeactivates = []

const testData = []

function randomUint256() {
  const buffer = []
  for (let i = 0; i < 64; i++) {
    buffer.push(
      Math.floor(Math.random() * 256)
        .toString(16)
        .padStart(2, '0')
    )
  }
  return buffer.join('')
}
function genRandomKey() {
  const key = [randomUint256(), randomUint256(), randomUint256(), randomUint256()].join('')
  return ['-----BEGIN MACI KEY-----', key, '-----END MACI KEY-----'].join('\n')
}
function privateKeyFromTxt(txt) {
  if (typeof txt !== 'string') {
    return
  }
  const key = txt.split('\n')[1] || ''
  if (key.length !== 512) {
    return
  }
  const keys = key.match(/[0-9a-f]{128}/g)
  if (keys.length !== 4) {
    return
  }
  const priKey = poseidon(keys.map((k) => BigInt('0x' + k)))
  return maci.genKeypair(priKey % maci.SNARK_FIELD_SIZE)
}

export default {
  name: 'MaciIndex',
  components: {
    Login,
  },
  computed: {
    ...mapState(['wallet', 'balance']),
    ...mapGetters(['account']),
    toDownloadUrl() {
      return 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.toDownloadKey)
    },
    toDownloadName() {
      return `dorahacks_maci_key_${this.focusedGrant.uname}_${Math.floor(Date.now() / 1000)}.txt`
    },
  },
  data() {
    return {
      grants: testData,

      stage: '',
      readyToAddNewKey: false,
      reseted: false,
      proofing: false,

      focusedGrant: null,
      toDownloadKey: '',
      inputKey: '',

      nextUpdateTime: new Date(
        Math.ceil(Date.now() / 43200000) * 43200000 + 7200000
      ).toLocaleString(),

      loading: false,
      downloaded: false,
      copied: false,
    }
  },
  watch: {
    stage() {
      this.readyToAddNewKey = false
      this.reseted = false
    },
    toDownloadKey() {
      this.downloaded = false
      this.copied = false
    },
  },
  async mounted() {
    const grants = await fetch(
      'https://s3.ap-southeast-1.amazonaws.com/asset.dorahacks/data/maci-info.json'
    )
      .then((res) => res.json())
      .then((data) =>
        data.map((grant) => ({ ...grant, coordPubKey: grant.coordPubKey.map((i) => BigInt(i)) }))
      )
      .catch(() => null)
    if (grants) {
      this.grants = grants
    }
  },
  methods: {
    back() {
      this.stage = ''
      this.inputKey = ''
    },
    signup(grant) {
      this.stage = 'signup'
      this.focusedGrant = grant
      this.toDownloadKey = genRandomKey()
    },
    reset(grant) {
      this.stage = 'reset'
      this.focusedGrant = grant
    },
    download() {
      const now = Math.floor(Date.now() / 1000)
      const url = 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.toDownloadKey)
      const link = document.createElement('a')
      link.setAttribute('download', `dorahacks_maci_key_${this.focusedGrant.uname}_${now}.txt`)
      link.style.display = 'none'
      link.href = url
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    copy() {
      navigator.clipboard.writeText(this.toDownloadKey)
      this.copied = true
    },
    upload() {
      const inputEl = document.createElement('input')
      inputEl.type = 'file'
      inputEl.value = ''
      inputEl.accept = 'text/plain'

      inputEl.click()
      inputEl.onchange = () => {
        const file = inputEl.files[0]
        const reader = new FileReader()
        reader.onload = () => {
          this.inputKey = reader.result
        }
        reader.readAsText(file)
        inputEl.value = ''
      }
    },
    async confirmSignup() {
      this.loading = true
      const ok = await switchChain(this.focusedGrant.chainId)
      if (!ok) {
        this.loading = false
        return
      }

      // const { signature, balance } = await getDoraGrantProofSign(
      //   this.focusedGrant.maci,
      //   'maci',
      //   this.account
      // )
      // const sign = signature + balance.slice(2)
      // if (Number(balance) === 0) {
      //   this.$message.error('Insufficient vcDORA quantity!')
      //   return
      // }
      const sign = '0x'

      this.download()

      const provider = this.focusedGrant.provider
      const web3 = new Web3(provider)

      const key = privateKeyFromTxt(this.toDownloadKey)
      const data = web3.eth.abi.encodeFunctionCall(
        {
          name: 'signUp',
          inputs: [
            {
              name: '_pubKey',
              type: 'tuple',
              components: [
                { name: 'x', type: 'uint256' },
                { name: 'y', type: 'uint256' },
              ],
            },
            { name: '_data', type: 'bytes' },
          ],
        },
        [maci.stringizing(key.pubKey), sign]
      )
      const tx = {
        from: this.account,
        to: this.focusedGrant.maci,
        data,
      }
      const txHash = await this.wallet.sendTransaction(tx).catch(() => '')
      this.loading = false
      if (!txHash) {
        return
      }
      this.stage = 'signup-success'
    },
    async confirmReset() {
      if (this.readyToAddNewKey) {
        return this.confirmAddKey()
      }

      const key = privateKeyFromTxt(this.inputKey)
      if (!key) {
        return this.$message.error('Invalid MACI key!')
      }

      const sharedKeyHash = poseidon(
        maci.genEcdhSharedKey(key.privKey, this.focusedGrant.coordPubKey)
      )
      const deactivates = await fetch(
        'https://s3.ap-southeast-1.amazonaws.com/asset.dorahacks/data/deactivates.json'
      )
        .then((res) => res.json())
        .then((data) => data.map((d) => d.map((n) => BigInt(n))))
      // const deactivates = testDeactivates // TODO
      const deactivateIdx = deactivates.findIndex((d) => d[4] === sharedKeyHash)

      if (deactivateIdx > -1) {
        // 已经验证过 deactivate 状态，可以生成证明了
        this.toDownloadKey = genRandomKey()
        this.readyToAddNewKey = true
        return
      }

      if (String(key.pubKey[0]) === localStorage.getItem('reseted-pubkey')) {
        this.stage = 'reset-success'
        this.reseted = true
        return
      }

      this.loading = true
      const ok = await switchChain(this.focusedGrant.chainId)
      if (!ok) {
        this.loading = false
        return
      }

      const provider = this.focusedGrant.provider
      const web3 = new Web3(provider)
      const data = web3.eth.abi.encodeFunctionCall(
        {
          inputs: [
            {
              name: '',
              type: 'uint256',
            },
          ],
          name: 'singuped',
          outputs: [
            {
              name: '',
              type: 'uint256',
            },
          ],
        },
        [maci.stringizing(key.pubKey[0])]
      )
      const res = await web3.eth
        .call({
          to: this.focusedGrant.maci,
          data,
        })
        .catch(() => '0x00')
      const stateIdx = Number(res) - 1
      if (stateIdx < 0) {
        this.loading = false
        return this.$message.error('The corresponding account does not have a signup!')
      }

      const msg = maci.batchGenMessage(stateIdx, key, this.focusedGrant.coordPubKey, [[0, 0]])

      const data2 = web3.eth.abi.encodeFunctionCall(
        {
          inputs: [
            {
              components: [{ name: 'data', type: 'uint256[7]' }],
              name: '_message',
              type: 'tuple',
            },
            {
              components: [
                { name: 'x', type: 'uint256' },
                { name: 'y', type: 'uint256' },
              ],
              name: '_encPubKey',
              type: 'tuple',
            },
          ],
          name: 'publishDeactivateMessage',
        },
        [msg[0][0], msg[1][0]]
      )

      const tx = {
        from: this.account,
        to: this.focusedGrant.maci,
        data: data2,
      }
      const txHash = await this.wallet.sendTransaction(tx)
      this.loading = false
      if (!txHash) {
        return
      }

      localStorage.setItem('reseted-pubkey', String(key.pubKey[0]))
      this.stage = 'reset-success'
    },
    async confirmAddKey() {
      this.loading = true
      const ok = await switchChain(this.focusedGrant.chainId)
      if (!ok) {
        this.loading = false
        return
      }

      this.download()

      const key = privateKeyFromTxt(this.inputKey)
      const newkey = privateKeyFromTxt(this.toDownloadKey)
      if (!key || !newkey) {
        this.loading = false
        return this.$message.error('Invalid MACI key!')
      }

      const sharedKeyHash = poseidon(
        maci.genEcdhSharedKey(key.privKey, this.focusedGrant.coordPubKey)
      )
      const deactivates = await fetch(
        'https://s3.ap-southeast-1.amazonaws.com/asset.dorahacks/data/deactivates.json'
      )
        .then((res) => res.json())
        .then((data) => data.map((d) => d.map((n) => BigInt(n))))
      // const deactivates = testDeactivates // TODO
      const deactivateIdx = deactivates.findIndex((d) => d[4] === sharedKeyHash)

      if (deactivateIdx === -1) {
        this.loading = false
        return
      }

      this.proofing = true
      const res = await maci
        .genAddKeyProof({
          coordPubKey: this.focusedGrant.coordPubKey,
          oldKey: key,
          deactivates,
          dIdx: deactivateIdx,
        })
        .catch((error) => {
          if (error.message) {
            this.$message.error(error.message)
          }
        })

      if (!res) {
        this.$message.error('Unable to generate proof!')
        this.proofing = false
        this.loading = false
        return
      }

      const provider = this.focusedGrant.provider
      const web3 = new Web3(provider)
      const data = web3.eth.abi.encodeFunctionCall(
        {
          inputs: [
            {
              components: [
                {
                  name: 'x',
                  type: 'uint256',
                },
                {
                  name: 'y',
                  type: 'uint256',
                },
              ],
              name: '_pubKey',
              type: 'tuple',
            },
            {
              name: '_nullifier',
              type: 'uint256',
            },
            {
              name: '_d',
              type: 'uint256[4]',
            },
            {
              name: '_proof',
              type: 'uint256[8]',
            },
          ],
          name: 'addNewKey',
          type: 'function',
        },
        [maci.stringizing(newkey.pubKey), res.nullifier, res.d, res.proof]
      )

      const tx = {
        from: this.account,
        to: this.focusedGrant.maci,
        data,
      }
      const txHash = await this.wallet.sendTransaction(tx)

      this.proofing = false
      this.loading = false

      if (!txHash) {
        return
      }

      this.stage = 'signup-success'
      this.reseted = true
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/css/theme.styl'

.modal-content
  padding 64px 96px
  h1
    margin-bottom 48px
    padding 0 16px
    font-size 24px
    line-height 32px
    font-weight 600
    > b
      color $orange
      font-weight 600
  textarea
    font-size 12px
    line-height 16px
    // background-color $white
    font-family: 'SF Mono', monospace
    &:disabled
      background-color #fafafa

  // COMMON STYLE
  .m-hr
    margin 24px -96px
    height 1px
    background-color $black + 90%
  .buttons, .m-buttons
    margin 0 -16px -16px 0
    height 64px
    display flex
    > div
      margin 0 16px 16px 0
  .m-notice
    margin-top 24px
    display flex
    justify-content space-between
    > img
      margin-right 16px
      flex 0 0 48px
      height 48px
      width 48px
    > div:last-child
      flex 1 1 auto
      padding 6px 0
      font-size 12px
      line-height 18px
    hr
      margin 24px 0
      height 1px
      background-color $black + 90%
    .o
      color $orange
    .b
      font-weight 600
    .u
      text-decoration underline

.grant-item
  display flex
  gap 16px
  &:not(:last-child)
    padding-bottom 24px
    margin-bottom 24px
    border-bottom 1px solid $black + 90%
  > img
    width 272px
    height 136px
    border-radius 8px
    background-color $black + 90%
  > div
    flex 1 1
  .title
    font-size 18px
    line-height 26px
    padding-bottom 4px
    font-weight 600
  .info
    margin-top 4px
    padding-top 4px
    border-top 1px solid $black + 90%
    font-size 14px
    line-height 20px
    display flex
    justify-content space-between
  .buttons
    margin-top 16px
    display flex
    gap 8px
  .main-button
    height 32px
    font-size 12px
    border-radius 6px

.upload-file
  color $orange
  font-weight 600
  text-decoration underline
  cursor pointer

.proofing-wrapper
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  background-color rgba($white, .8)
  backdrop-filter blur(20px)
  > div
    margin 64px auto
    max-width 368px
    display flex
    flex-direction column
    justify-content center
    align-items center
    text-align center
    gap 16px
  .title
    font-size 24px
    line-height 32px
    font-weight bold

.small-buttons
  padding-top 2px
  padding-left 16px
  display flex
  gap 16px
  font-size 12px
  line-height 18px
  font-weight 600
  > p
    cursor pointer

@media not all and (max-width 750px)
  .pc-hidden
    display none

@media (max-width 750px)
  .mo-hidden
    display none
  .modal-content
    padding 48px 16px
  .grant-item
    flex-direction column
</style>
