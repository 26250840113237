<template>
  <div class="user-agreement">
    <img src="~@/assets/icons/alert_48.svg" />
    <h1>User Agreement</h1>
    <p>
      Please carefully read the description of “Stake” in this reminder, to understand the risk
      characteristics and decide whether to stake according to your participation purpose, and asset
      status, etc.
    </p>
    <ol>
      <li>
        Dora Staking is a way to participate in governance of Dora grant communities (e.g. vote for
        grant DAO BUIDLs on DoraHacks.io). Please read through the Dora Staking Guide (<a
          href="https://dorahacks.io/blog/guides/vcdora/"
          target="_blank"
          ><u>https://dorahacks.io/blog/guides/vcdora/</u></a
        >) in more details.
      </li>
      <li>
        By staking $DORA, you will receive corresponding amount of vcDORA. vcDORA, short for “voice
        credit Dora”, is a community governance token on DoraHacks.io, generated by Dora staking.
        The amount of vcDORA associated with an address is related to the amount of DORA staked, the
        duration of staking, and time elapsed since the initial staking date. During the period of
        staking, the amount of vcDORA you hold will be decaying linearly until the end of staking
        period. vcDORA is not allowed to be transferred.
      </li>
      <li>
        After the initial staking, you are allowed to increase the staking amount and/or to extend
        the staking period, however you are not allowed to unstake before the end of the staking
        period.
      </li>
      <li>
        You should fully understand that your staking is irrevocable after delivered until the end
        of staking period and DoraHacks is unable to revoke any of your instructions. The staking
        system is decentralized and maintained through the staking contracts. DoraHacks is unable to
        interfere or control over this matter and will not be liable for any losses caused by attack
        or other force majeure.
      </li>
      <li>
        Please make sure you are not a citizen of the United States of America, the People's
        Republic of China, or OFAC sanctioned country before you vote. Other countries include, but
        are not limited to Cuba, Ukraine's Crimea region, the Democratic Republic of Korea, Iraq,
        Syria, Iran, etc. Also, please confirm that you are not on the OFAC's or any government's
        sanctions list. If you are a citizen of one of the countries above mentioned, please do not
        participate in ‘Grant’ or ‘Vote’. DoraHacks may not be able to verify the legitimacy or
        authenticity of your nationality or country of permanent residence. Please comply with the
        jurisdictional requirements of your country of residence.
      </li>
      <li>
        The regulatory regime governing blockchain technology, cryptocurrencies and digital
        transactions is still working in progress and new regulations or policies could have a
        material adverse impact on the development of digital asset transactions and/or services and
        the utility of digital currency assets. The service platform may be subject to the risk of
        being terminated, and you may bear the risk of losses, of violating anti-money laundering
        compliance and other uncertain risks of economic loss and legal liability.
      </li>
      <li>
        DoraHacks service reserves the right to modify and adjust the User Agreement and the
        separate agreements of each functional section, related business rules and statements, etc.
        at any time. Once the above-mentioned contents are officially released, it is an integral
        part of the User agreement, and you shall also comply with it. Your acceptance of any of the
        foregoing single agreements or statements is deemed to be your full acceptance of all
        DoraHacks's other documentation related to that feature.
      </li>
    </ol>
    <div class="main-button" major @click="confirm">Agree and Continue to Stake</div>
  </div>
</template>

<script>
export default {
  methods: {
    confirm() {
      this.$emit('confirm')
      this.$emit('close')
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/css/theme.styl'

.user-agreement
  display flex
  flex-direction column
  align-items center
  font-size 16px
  line-height 24px
  > img
    width 96px
  > h1
    margin 8px auto 16px
    color $orange
  ol
    list-style decimal
    padding-left 20px
  li
    margin-top 24px
  .main-button
    margin-top 56px
</style>
