<template>
  <div class="icon-loading">
    <i />
    <i />
  </div>
</template>

<script>
export default {
  name: 'LoadingIcon',
}
</script>

<style lang="stylus" scoped>
@import '~@/css/theme.styl'

.icon-loading
  width 40px
  height 20px
  position relative
  > i
    position absolute
    top 0
    left 10px
    display block
    width 20px
    height 20px
    border-radius 10px
    opacity .9
    transform-origin center
  > i:first-child
    background-color $orange
    animation loading 1s infinite linear
  > i:last-child
    background-color $black
    animation loading 1s -0.5s infinite linear

@keyframes loading
  0%
    transform  scale(.84) translateX(-13px)
    z-index 0
  50%
    transform  scale(.84) translateX(13px)
    z-index 0
  75%
    transform  scale(1) translateX(0)
    z-index 2
  100%
    transform  scale(.84) translateX(-13px)
    z-index 0
</style>
