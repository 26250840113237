<template>
  <div>
    <div style="overflow: auto">
      <table class="ds-table" style="min-width: 600px">
        <tr>
          <th>Start date</th>
          <th>End date</th>
          <th>Txn ID</th>
          <th>$DORA</th>
          <th>vcDORA</th>
          <th>% of power</th>
        </tr>
        <tr v-for="item in displayList" :key="item.txHash" :pending="item.local">
          <td>{{ item.startDate }}</td>
          <td>{{ item.endDate }}</td>
          <td>
            <a :href="`https://etherscan.io/tx/${item.txHash}`" target="_blank" class="link">
              {{ item.addrTxHash }}
            </a>
          </td>
          <td>{{ item.amount }}</td>
          <td>{{ item.balance }}</td>
          <td>{{ item.proportion }}</td>
        </tr>
        <div v-if="data.length === 0" class="stake">
          <router-link to="/stake" class="main-button" major>Stake Now!</router-link>
        </div>
      </table>
    </div>
    <div class="ds-table-pagination">
      <div class="button" :active="page > 1" @click="jump(-1)">
        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.369 6.002a.529.529 0 00.167.386L7.1 10.844A.505.505 0 007.47 11a.536.536 0 00.27-.07.507.507 0 00.26-.454.533.533 0 00-.15-.375l-4.508-4.41v.617l4.508-4.41a.547.547 0 00.08-.642.508.508 0 00-.19-.187A.535.535 0 007.47 1a.518.518 0 00-.375.155l-4.56 4.456a.55.55 0 00-.166.391z"
          />
        </svg>
        <span>Prev</span>
      </div>
      <div class="pages">
        <span>{{ pageStr }}</span> of {{ pagesNum }}
      </div>
      <div class="button" :active="page < pagesNum" @click="jump(1)">
        <span>Next</span>
        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.631 5.998a.529.529 0 00-.167-.386L4.9 1.156A.504.504 0 004.53 1a.536.536 0 00-.27.07.508.508 0 00-.26.454.533.533 0 00.15.375l4.508 4.41v-.617l-4.508 4.41a.547.547 0 00-.08.642.508.508 0 00.19.187c.082.046.175.07.27.069a.516.516 0 00.375-.155l4.56-4.456a.55.55 0 00.166-.391z"
          />
        </svg>
      </div>
    </div>
    <div v-if="data.length !== 0" class="stake">
      <router-link to="/stake" class="main-button" major> Adjust my staking</router-link>
    </div>
  </div>
</template>

<script>
import { ARRAY_PROPS } from '@/assets/utils'

export default {
  name: 'UserTable',
  props: {
    data: ARRAY_PROPS,
  },
  data() {
    return {
      page: 1,
    }
  },
  computed: {
    pagesNum() {
      return Math.ceil(this.data.length / 10) || 1
    },
    pageStr() {
      return String(this.page).padStart(String(this.pagesNum).length, '0')
    },
    displayList() {
      const start = (this.page - 1) * 10
      const dataCopy = [...this.data]
      return dataCopy.sort((a, b) => b.ts - a.ts).slice(start, start + 10)
    },
  },
  methods: {
    jump(dir) {
      const page = this.page + dir
      if (page < 1 || page > this.pagesNum) return

      this.page = page
    },
  },
}
</script>

<style lang="stylus" scoped>
.stake
  padding 14px
  display flex

@media (min-width: 600px)
  .stake
    justify-content center
</style>
