import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import Web3 from 'web3'
import { Multicall } from 'ethereum-multicall'

import config from '@/config.json'
import vcDoraAbi from '@/assets/vcDORA.abi.json'
import { amountFormatter, hexFormatter } from '@/assets/utils'
import { parseTimestamp } from '@/assets/utils/time'

const web3 = new Web3(config.provider)
const multicall = new Multicall({
  web3Instance: web3,
  multicallCustomContractAddress: config.multicall,
})

const caculateLockTime = (ts) => {
  const weeks = Math.ceil(ts / 7 / 86400000)
  switch (weeks) {
    case 208:
      return '4 years'
    case 156:
      return '3 years'
    case 104:
      return '2 years'
    case 52:
      return '1 year'
    case 26:
      return '6 months'
    case 13:
      return '3 months'
    case 4:
      return '3 month'
    case 1:
      return '1 week'
    default:
      return weeks + ' weeks'
  }
}

const maxtime = 208 * 7 * 86400000
const parseLog = (totalSupply) => (log) => {
  const amount = Number(log.amount) / 1e18
  const vcDoraBalance = amount * ((log.locktime - log.ts) / maxtime)
  return {
    ...log,
    addrAccount: hexFormatter(log.user),
    startDate: parseTimestamp(log.ts).slice(0, 10),
    endDate: parseTimestamp(log.locktime).slice(0, 10),
    lockTime: caculateLockTime(log.locktime - log.ts),
    addrTxHash: hexFormatter(log.txHash),
    amount: amountFormatter(amount * 100),
    balance: amountFormatter(vcDoraBalance),
    proportion: (Math.min(1, vcDoraBalance / totalSupply) * 100).toFixed(2) + '%',
  }
}

export default new Vuex.Store({
  state: {
    // login status
    wallet: null,
    chainId: 0,
    account: '',

    // toggle user
    loading: false,

    // user stats
    needApprove: true,
    locked: 0,
    lockEnd: 0,
    supply: 0,
    balance: 0,

    // global stats
    stats: {
      totalLocked: '0',
      totalSupply: '0',
      avgLockTime: 0,
    },
    stakingHistory: [],
    futureSupply: [],
    userBalance: [],
    userLocked: [],
  },
  getters: {
    account(state) {
      if (state.chainId === config.chainId) {
        return state.account
      } else {
        // return ''
        return state.account
      }
    },
    // balance / supply (%)
    proportion(state) {
      if (state.balance === 0 || state.supply === 0) {
        return '0.00'
      }
      const p = (state.balance / state.supply) * 100
      return p.toFixed(2)
    },
    userStakingHistory(state) {
      if (!state.account) return []

      return state.stakingHistory.filter((item) => item.user === state.account)
    },
  },
  mutations: {
    setWallet(state, wallet) {
      if (state.wallet && typeof state.wallet.destroy === 'function') {
        state.wallet.destroy()
      }
      if (wallet) {
        localStorage.setItem('vcd:wallet', wallet.name)
      } else {
        localStorage.removeItem('vcd:wallet')
      }
      state.wallet = wallet
    },
    setChain(state, chainId) {
      state.chainId = chainId
    },
    setAccount(state, account) {
      state.account = account && web3.utils.toChecksumAddress(account)
    },
    updateState(state, payload) {
      for (const key in payload) {
        if (Object.prototype.hasOwnProperty.call(state, key)) {
          state[key] = payload[key]
        }
      }
    },
    addPendingStakingLog(state, log) {
      if (state.stakingHistory.find((item) => item.txHash === log.txHash)) {
        return
      }

      log.local = true
      state.stakingHistory.unshift(parseLog(Number(state.stats.totalSupply) / 1e18)(log))
      let logs
      try {
        logs = JSON.parse(localStorage.getItem('vcd:logs') || '[]')
      } catch {
        logs = []
      }
      logs.unshift(log)
      localStorage.setItem('vcd:logs', JSON.stringify(logs))
    },
  },
  actions: {
    async updateStats({ commit }) {
      const data = await fetch(config.backendApi).then((res) => res.json())

      data.stats.totalLocked = data.stats.totalLocked + '00'

      const totalSupply = Number(data.stats.totalSupply) / 1e18

      const allTxHash = new Set()
      for (const log of data.stakingHistory) {
        allTxHash.add(log.txHash)
      }

      let localLogs
      try {
        localLogs = JSON.parse(localStorage.getItem('vcd:logs') || '[]')
      } catch {
        localLogs = []
      }

      localLogs = localLogs.filter((item) => {
        // 800 seconds time out
        return item.ts < Date.now() + 800000 && !allTxHash.has(item.txHash)
      })

      localStorage.setItem('vcd:logs', JSON.stringify(localLogs))

      data.stakingHistory = [...localLogs, ...data.stakingHistory].map(parseLog(totalSupply))
      commit('updateState', data)
    },
    async updateBalance({ state, commit }) {
      if (!state.account) {
        commit('updateState', {
          locked: 0,
          lockEnd: 0,
          supply: 0,
          balance: 0,
        })
        return
      }
      commit('updateState', { loading: true })
      const contractCallContext = [
        {
          reference: 'vcDora',
          contractAddress: config.vcDora,
          abi: vcDoraAbi,
          calls: [
            { reference: 'locked', methodName: 'locked', methodParameters: [state.account] },
            { reference: 'supply', methodName: 'totalSupply', methodParameters: [] },
            { reference: 'balance', methodName: 'balanceOf', methodParameters: [state.account] },
          ],
        },
      ]
      const callResults = await multicall.call(contractCallContext)
      try {
        const data = callResults.results.vcDora.callsReturnContext
        commit('updateState', {
          loading: false,
          locked: Number(data[0].returnValues[0].hex) / 1e16,
          lockEnd: Number(data[0].returnValues[1].hex) * 1000,
          supply: Number(data[1].returnValues[0].hex) / 1e18,
          balance: Number(data[2].returnValues[0].hex) / 1e18,
        })
      } catch {
        commit('updateState', {
          loading: false,
          locked: 0,
          lockEnd: 0,
          supply: 0,
          balance: 0,
        })
      }
    },
    async updateAllowance({ state, commit }) {
      if (!state.account) {
        commit('updateState', { needApprove: true })
        return
      }

      return web3.eth
        .call({
          to: config.dora,
          data:
            '0xdd62ed3e' +
            state.account.slice(2).toLowerCase().padStart(64, '0') +
            config.vcDora.slice(2).toLowerCase().padStart(64, '0'),
        })
        .then((data) => {
          commit('updateState', { needApprove: Number(data) === 0 })
        })
        .catch(() => {
          commit('updateState', { needApprove: true })
        })
    },
    async getTxReceipt(_, txHash) {
      return web3.eth
        .getTransactionReceipt(txHash)
        .then((tx) => {
          return !!tx
        })
        .catch(() => {
          return false
        })
    },
  },
})
