/* eslint-disable no-unused-vars */

export default class Wallet {
  name = ''

  constructor(name, _options) {
    this.name = name
  }

  static async connect({ chainId, setAccount, setChainId }) {
    return Promise.reject()
  }

  destroy() {}

  async sendTransaction(tx) {
    return false
  }
}
