<template>
  <div :withtips="!!config.tips" class="ui-form-item">
    <label v-if="config.label" :necessary="config.necessary" :for="id">{{ config.label }}</label>
    <slot />
    <div v-if="config.prefix" class="prefix">
      {{ config.prefix }}
    </div>
    <div
      v-if="config.disabled"
      :style="{ paddingLeft: `${config.prefixWidth + 15}px` }"
      class="input-box-disabled"
    >
      {{ value }}
    </div>
    <div v-else :focus="focused" :class="{ 'input-container': options.length }">
      <template v-if="config.type === 'textarea'">
        <textarea
          :id="id"
          v-model="inputValue"
          :placeholder="config.placeholder"
          :disabled="holding"
          :error="!!error"
          :rows="config.rows"
          :maxlength="config.maxlength"
          class="input-box input-textarea"
          @input="oninput"
          @blur="sync"
        />
        <div v-if="config.maxlength" class="counter">
          {{ `${inputValue.length}/${config.maxlength}` }}
        </div>
      </template>

      <input
        v-else
        :id="id"
        v-model="inputValue"
        :placeholder="config.placeholder"
        :disabled="holding"
        :error="!!error"
        :type="config.type || 'text'"
        :style="{ paddingLeft: config.prefixWidth ? `${config.prefixWidth + 15}px` : '15px' }"
        class="input-box"
        @input="oninput"
        @click="onFocus"
      />
      <transition name="options">
        <ul v-if="options.length && focused" class="ui-form-options">
          <li v-for="(item, i) in options" :key="i" @click="select(item)">
            {{ nameOf(item) }}
          </li>
        </ul>
      </transition>
    </div>
    <div v-if="config.suffix" class="suffix">
      {{ config.suffix }}
    </div>
    <div class="notice-box">
      <span v-if="error && error !== 'empty'" error>{{ error }}</span>
      <span v-else-if="!!config.tips">{{ config.tips }}</span>
    </div>
    <div v-if="holding" class="loading-layer">
      <Loading />
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import { STRING_PROPS } from '@/assets/utils'
import Loading from '@/components/ui/icons/Loading'

export default {
  name: 'InputText',
  components: {
    Loading,
  },
  mixins: [mixin],
  props: {
    config: {
      type: Object,
      default: () => ({
        label: 'The Label of this Input',
        placeholder: 'Placeholder',
        necessary: true,
        disabled: false,
        verify: () => '',
        tips: '',
        type: 'text',
        suffix: '',
        prefix: '',
        prefixWidth: 0,
        rows: 6,
        maxlength: '',
        options: [],
      }),
    },
    value: STRING_PROPS,
  },
  data() {
    return {
      type: 'input',
      inputValue: this.value,
      focused: false,
    }
  },
  computed: {
    options() {
      return this.config.options || []
    },
  },
  watch: {
    value(v) {
      this.inputValue = String(v)
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onBlur, true)
  },
  methods: {
    isEmpty(value) {
      return !value
    },
    oninput() {
      this.realTimeSync()
      this.error = ''
      this.focused = false
    },
    nameOf(option) {
      return Array.isArray(option) ? option[0] : option
    },
    valueOf(option) {
      return Array.isArray(option) ? option[1] : option
    },
    onFocus() {
      this.focused = true
      document.addEventListener('click', this.onBlur, true)
    },
    onBlur() {
      this.focused = false
      this.sync()
      document.removeEventListener('click', this.onBlur, true)
    },
    select(option) {
      this.inputValue = this.valueOf(option).toString()
      this.sync()
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/css/theme.styl'

.counter
  position relative
  top -40px
  right 16px
  color $black + 50%
  text-align right
</style>
