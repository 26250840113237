<template>
  <div class="wallet-bubble">
    <p class="title">
      <span>Wallet connected</span>
      <u @click="disconnect">Disconnect</u>
    </p>

    <section :error="false" class="wallet-info">
      <div>
        <p>
          <span class="label">Account:</span>
          <b>{{ abbrAccount }}</b>
        </p>
        <p>
          <span class="label">Network:</span>
          <b class="curr-net">{{ networkName(chainId) }}</b>
          <!-- <span v-if="chainId !== requiredChainId">
            {{ $t('components_chain-requireChain') }}
            <b>{{ networkName(requiredChainId) }}</b>
          </span> -->
        </p>
      </div>
      <img :src="require(`@/components/chain/logo/${logoName}.svg`)" width="48" />
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { hexFormatter } from '@/assets/utils'

export default {
  name: 'WalletBubble',
  computed: {
    ...mapState(['wallet', 'chainId', 'account']),
    logoName() {
      if (this.wallet) {
        return this.wallet.name
      } else {
        return 'metamask'
      }
    },
    abbrAccount() {
      return hexFormatter(this.account)
    },
  },
  methods: {
    ...mapMutations(['setWallet', 'setChain', 'setAccount']),
    networkName(chainId) {
      switch (chainId) {
        case 1:
          return 'Ethereum Mainnet'
        case 1337:
          return 'Local'
        default:
          return 'Unknow'
      }
    },
    disconnect() {
      this.setWallet(null)
      this.setChain(0)
      this.setAccount('')
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/css/theme.styl'

.wallet-bubble
  margin-top 6px
  padding 16px
  width 304px
  color $black
  background-color $white
  border-radius 4px
  filter drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.08)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.06)) drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.04))
  &:after
    content ''
    position absolute
    top -6px
    right 14px
    border-left 6px solid transparent
    border-right 6px solid transparent
    border-bottom 6px solid $white
  .title
    padding 0 16px
    display flex
    justify-content space-between
    align-items center
    font-size 12px
    line-height 18px
    > u
      color $orange
      font-weight 600
      cursor pointer

.wallet-info
  margin-top 6px
  padding 16px 8px 16px 16px
  display flex
  justify-content space-between
  border-radius 4px
  background-color $orange + 92%
  font-size 12px
  line-height 18px
  color $orange
  position relative
  transition color .2s, background-color .2s
  &[error]
    background-color $error + 94%
    color $error
    .curr-net
      text-decoration line-through
  > div
    display flex
    flex-direction column
    justify-content center
  .label
    display inline-block
    min-width 70px
  img
    object-fit contain
</style>
