import Wallet from './basic'

function waitChainChange(chainId, resolve, timeout) {
  if (typeof window.ethereum === 'undefined' || timeout < Date.now()) {
    return resolve(false)
  }
  if (Number(window.ethereum.chainId) === chainId) {
    return resolve(true)
  } else {
    requestAnimationFrame(() => {
      waitChainChange(chainId, resolve, timeout)
    })
  }
}

export function switchChain(chainId) {
  if (typeof window.ethereum === 'undefined') return false
  const chainIdHex = '0x' + chainId.toString(16)
  return window.ethereum
    .request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainIdHex }],
    })
    .then(
      () =>
        new Promise((resolve) => {
          waitChainChange(chainId, resolve, Date.now() + 10000)
        })
    )
    .catch(() => false)
}

class MetamaskWallet extends Wallet {
  _destroy = () => undefined

  constructor({ destroy }) {
    super('metamask')

    this._destroy = destroy
  }

  // static async connect({ chainId, setChain, setAccount }) {
  static async connect({ setChain, setAccount }) {
    if (typeof window.ethereum === 'undefined') {
      if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
        return Promise.reject('mobile')
      } else {
        return Promise.reject('MetaMask not installed!')
      }
    }

    function updateChain(chainId) {
      setChain(Number(chainId))
    }
    function updateAccount(accounts) {
      setAccount(accounts[0])
    }

    const ethereum = window.ethereum
    try {
      await ethereum.enable()
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })

      // await switchChain(chainId)
      // if (Number(ethereum.chainId) !== chainId) {
      //   return Promise.reject('Wrong network!')
      // }

      updateChain(ethereum.chainId)
      updateAccount(accounts)
    } catch (err) {
      if (!err.code === -32002) {
        return Promise.reject('MetaMask not installed!')
      } else {
        return Promise.reject('Unknow error!')
      }
    }

    ethereum.addListener('chainChanged', updateChain)
    ethereum.addListener('accountsChanged', updateAccount)

    const destroy = () => {
      ethereum.removeListener('chainChanged', updateChain)
      ethereum.removeListener('accountsChanged', updateAccount)
    }

    return new MetamaskWallet({ destroy })
  }

  destroy() {
    this._destroy()
  }

  async sendTransaction(tx) {
    return window.ethereum
      .request({
        method: 'eth_sendTransaction',
        params: [tx],
      })
      .catch(() => false)
  }
}

import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'

class WcWallet extends Wallet {
  connecto = null

  constructor({ connector }) {
    super('walletconnect')

    this.connector = connector
  }

  static async connect({ chainId, setChain, setAccount }) {
    function updateChain(chainId) {
      setChain(Number(chainId))
    }
    function updateAccount(accounts) {
      setAccount(accounts[0])
    }

    const connector = new WalletConnect({
      bridge: 'https://bridge.walletconnect.org', // Required
      qrcodeModal: QRCodeModal,
    })
    connector.on('session_update', (error, payload) => {
      if (error) {
        return
      }
      const { chainId, accounts } = payload.params[0]
      updateChain(chainId)
      updateAccount(accounts)
    })
    connector.on('disconnect', () => {
      updateChain(0)
      updateAccount([''])
    })
    const payload = await connector.connect({ chainId }).catch(() => false)
    if (!payload) {
      return Promise.reject('Connect fail!')
    }
    updateChain(payload.chainId)
    updateAccount(payload.accounts)

    return new WcWallet({ connector })
  }

  destroy() {
    localStorage.removeItem('walletconnect')
  }

  async sendTransaction(tx) {
    return this.connector.sendTransaction(tx)
  }
}

export default {
  metamask: MetamaskWallet,
  walletconnect: WcWallet,
}
