<template>
  <div class="ui-form-item">
    <label v-if="config.label" :necessary="config.necessary" :for="id">{{ config.label }}</label>
    <slot />
    <div :focus="focused" class="select-container" :disabled="config.disabled">
      <div v-if="config.disabled" class="input-box-disabled">{{ value }}</div>
      <input
        v-else
        :id="id"
        ref="input"
        :placeholder="config.placeholder"
        :value="nameOf(inputValue)"
        :error="!!error"
        class="input-box input-select-box"
        type="text"
        readonly
        @click="onFocus"
      />
      <transition name="options">
        <ul v-if="focused" class="ui-form-options">
          <div v-if="optionsUpdating" class="loading">
            <Loading />
          </div>
          <li v-else-if="config.options.length === 0" class="disabled" @click="select('')">
            no options available now
          </li>
          <li v-for="(item, i) in config.options" :key="i" @click="select(item)">
            {{ nameOf(item) }}
          </li>
        </ul>
      </transition>
    </div>
    <div class="notice-box">
      <span v-if="error && error !== 'empty'" error>{{ error }}</span>
      <span v-else-if="!!config.tips">{{ config.tips }}</span>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import { BOOLEAN_PROPS } from '@/assets/utils'
import Loading from '@/components/ui/icons/Loading'

export default {
  name: 'InputSelect',
  components: {
    Loading,
  },
  mixins: [mixin],
  props: {
    config: {
      type: Object,
      default: () => ({
        label: 'The Label of this Input',
        placeholder: 'Placeholder',
        necessary: true,
        disabled: false,
        verify: () => '',
        tips: '',
        options: ['option 1', ['option 2', 'value 2'], 'option 3'],
      }),
    },
    value: {
      type: [String, Array],
      default: '',
    },
    optionsUpdating: BOOLEAN_PROPS,
  },
  data() {
    return {
      type: 'input-select',
      inputValue: this.value,
      focused: false,
    }
  },
  watch: {
    value(v) {
      this.inputValue = v
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onBlur, true)
  },
  methods: {
    onFocus() {
      this.focused = true
      document.addEventListener('click', this.onBlur, true)
    },
    onBlur(e) {
      if (e) {
        if (this.$el.contains(e.target) && e.target !== this.$refs.input) {
          return
        }
      }
      if (e) {
        e.stopPropagation()
      }
      this.focused = false
      document.removeEventListener('click', this.onBlur, true)
      this.sync()
    },
    nameOf(option) {
      return Array.isArray(option) ? option[0] : option
    },
    valueOf(option) {
      return Array.isArray(option) ? option[1] : option
    },
    select(item) {
      this.inputValue = item
      this.onBlur()
    },
    isEmpty(value) {
      return typeof this.valueOf(value) === 'undefined'
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/css/theme.styl'

.loading
  height 40px
  display flex
  justify-content center
  align-items center
</style>
