<template>
  <div class="ui-card-options">
    <div @click="connect('metamask')">
      <span>MetaMask</span>
      <img src="./logo/metamask.svg" width="40" />
    </div>
    <div @click="connect('walletconnect')">
      <span>WalletConnect</span>
      <img src="./logo/walletconnect.svg" width="40" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import LinkWalletHelp from './feature/LinkWalletHelp'

import EthereumWallet from './libs/EthereumWallet'

import config from '@/config.json'

export default {
  name: 'EthereumAccount',
  methods: {
    ...mapMutations(['setWallet', 'setChain', 'setAccount']),
    async connect(type) {
      const Wallet = EthereumWallet[type]
      if (!Wallet) return

      const payload = {
        chainId: config.chainId,
        setChain: this.setChain,
        setAccount: this.setAccount,
      }
      const ok = await Wallet.connect(payload)
        .then((wallet) => {
          this.setWallet(wallet)
          return true
        })
        .catch((reason) => {
          if (reason === 'mobile') {
            this.$modal(<LinkWalletHelp />, { returnable: true })
          }
          this.$message.error(reason)
          return false
        })

      if (ok) {
        this.$emit('connect')
      }
    },
  },
}
</script>
