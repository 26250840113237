import Vue from 'vue'

import ModalContainer from '@/components/ModalContainer'

const modals = []

function freezeBody() {
  const dy = window.scrollY
  const s = document.body.style
  if (s.position !== 'fixed') {
    s.position = 'fixed'
    s.top = `${-dy}px`
    s.width = '100%'
    if (document.body.offsetHeight > window.innerHeight) {
      s.overflowY = 'scroll'
    }
  }
  window.onbeforeunload = () => '42'
}

function unfreezeBody() {
  const s = document.body.style
  const dy = Number(s.top.slice(1, -2))

  s.position = ''
  s.top = ''
  s.width = ''
  s.overflowY = ''

  window.scrollTo(0, dy)
  window.onbeforeunload = null
}

function initModalContainer() {
  let container = document.querySelector('#modal-container')
  if (!container) {
    container = document.createElement('div')
    container.id = 'modal-container'
    document.body.appendChild(container)
  }

  container.style.display = 'unset'
  freezeBody()

  return container
}

function removeModalContainer(closeEvent) {
  for (let i = modals.length - 1; i >= 0; i--) {
    const m = modals[i]
    m.callback(i === 0 ? closeEvent : undefined)
    m.vm.$destroy()
  }

  modals.splice(0)

  const container = document.querySelector('#modal-container')
  container.innerHTML = ''
  container.style.display = 'none'

  unfreezeBody()
}

function popModal(continueEvent) {
  if (modals.length === 1) {
    return removeModalContainer(continueEvent)
  }
  const poped = modals.pop()

  poped.vm.$children[0].hide = true
  poped.callback(continueEvent)
  const container = document.querySelector('#modal-container')
  setTimeout(() => {
    container.removeChild(poped.vm.$el)
    poped.vm.$destroy()
  }, 500)

  const tail = modals[modals.length - 1]
  const modal = tail.vm.$children[0]
  modal.hide = false
}

function bindListeners(vNode) {
  if (vNode.componentOptions) {
    vNode.componentOptions.listeners = {
      ...vNode.componentOptions.listeners,
      close: removeModalContainer,
      continue: popModal,
    }
  }
  if (Array.isArray(vNode.children)) {
    vNode.children.forEach(bindListeners)
  }
}

function modal(contentSlot, props = {}) {
  const container = initModalContainer()

  const mBody = document.createElement('div')
  container.appendChild(mBody)

  if (contentSlot) {
    bindListeners(contentSlot)
  }

  for (let i = 0; i < modals.length; i++) {
    const tail = modals[i]
    const modal = tail.vm.$children[0]
    modal.hide = true
  }

  const vm = new Vue({
    router: this.$root.$router,
    store: this.$root.$store,
    i18n: this.$root.$i18n,
    $marked: this.$root.$marked,
    $cdn: this.$root.$cdn,
    render: (h) =>
      h(ModalContainer, {
        props,
        on: {
          close: removeModalContainer,
          continue: popModal,
        },
        scopedSlots: {
          default: () => contentSlot,
        },
      }),
  }).$mount(mBody)

  return new Promise((resolve) => {
    modals.push({
      vm,
      callback: resolve,
    })
  })
}

const ModalPlugin = {
  install: (Vue) => {
    Vue.prototype.$modal = modal
  },
}

export default ModalPlugin
