<template>
  <StakeHome>
    <div>
      <ul class="ds-stats">
        <li>
          <b>{{ totalLocked }}</b>
          <span>Total $DORA locked</span>
        </li>
        <li>
          <b>{{ totalSupply }}</b>
          <span>Total vcDORA</span>
        </li>
        <li>
          <b>{{ avgLockYears }}<small>years</small></b>
          <span>Average lock time</span>
        </li>
      </ul>

      <section class="ds-graph">
        <h2>Total governance power (vcDORA)</h2>
        <Chart :data="futureSupply" />
      </section>

      <section class="ds-staking">
        <h2>Overall staking activities</h2>
        <Table :data="stakingHistory" />
      </section>

      <section>
        <h2>Staking and governance power distribution (top 50)</h2>
        <div class="pie-container">
          <Pie :data="pieData" :dataType="pieType" />
          <div class="pie-selector">
            <span :focus="pieType === '$DORA'" @click="pieType = '$DORA'">$DORA</span>
            <span :focus="pieType === 'vcDORA'" @click="pieType = 'vcDORA'">vcDORA</span>
          </div>
        </div>
      </section>

      <section v-if="!account" class="ds-block">
        <Login />
      </section>
    </div>
  </StakeHome>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { numFormatter } from '@/assets/utils'
import StakeHome from '@/components/StakeHome'
import Login from '@/components/modal/Login'
import Chart from '@/components/Chart'
import Pie from '@/components/Pie'
import Table from '@/components/Table'

export default {
  name: 'ds',
  components: {
    StakeHome,
    Login,
    Chart,
    Pie,
    Table,
  },
  data() {
    return {
      pieType: 'vcDORA',
    }
  },
  computed: {
    ...mapState(['stats', 'futureSupply', 'stakingHistory', 'userBalance', 'userLocked']),
    ...mapGetters(['account']),
    totalLocked() {
      return numFormatter(Number(this.stats.totalLocked) / 1e18, 2)
    },
    totalSupply() {
      return numFormatter(Number(this.stats.totalSupply) / 1e18, 2)
    },
    avgLockYears() {
      return (this.stats.avgLockTime / 52 / 7 / 86400000).toFixed(2)
    },
    pieData() {
      return (this.pieType === 'vcDORA' ? this.userBalance : this.userLocked).map((item) => [
        item[1],
        item[0],
      ])
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/css/theme.styl'

.pie-container
  margin-top 24px
  display flex
  justify-content space-between
  align-items center
.pie-selector
  padding 3px
  border 1px solid $black + 90%
  width fit-content
  // height 40px
  border-radius 20px
  display flex
  gap 8px
  span
    padding 4px 12px
    border-radius 16px
    font-size 16px
    line-height 24px
    font-weight 600
    transition color .14s, background-color .14s
    cursor pointer
  span[focus]
    background-color $black
    color $white
</style>
