<template>
  <div style="overflow: auto">
    <div style="margin-top: 8px"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core'
import { TooltipComponent, GridComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { ARRAY_PROPS, numFormatter } from '@/assets/utils'
import { parseTimestamp } from '@/assets/utils/time'

import symbol from '@/assets/icons/symbol_16.svg'

echarts.use([TooltipComponent, GridComponent, LineChart, CanvasRenderer])

const genOption = (echartsInstance, data) => ({
  grid: {
    left: 0,
    bottom: 26,
    top: 8,
    right: 32,
  },
  tooltip: {
    trigger: 'axis',
    className: 'tooltip',
    position: (_point, params) => {
      params = params[0]
      const pos = echartsInstance.convertToPixel({ seriesId: params.seriesId }, params.value)
      return [pos[0] + 7, pos[1] - 22]
    },
    formatter: (params) => {
      params = params[0]
      return [
        '<div>',
        `<p>vcDora: <b>${params.value[1].toFixed(2)}</b></p>`,
        `<p>Date: <b>${parseTimestamp(params.value[0]).substring(0, 10)}</b></p>`,
        '</div>',
      ].join('')
    },
    axisPointer: {
      lineStyle: {
        color: '#e5e5e9',
        type: 'solid',
      },
      animation: false,
    },
    backgroundColor: '#0e0e11',
    borderWidth: 0,
    padding: [4, 8],
    textStyle: {
      color: '#fff',
      fontFamily: 'system-ui',
      fontSize: 12,
      lineHeight: 18,
    },
  },
  xAxis: {
    type: 'time',
    splitNumber: 5,
    boundaryGap: ['5%', '5%'],
    minInterval: 30 * 86400000,
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      formatter: '{yyyy}/{MM}',
      color: '#7c7c93',
    },
  },
  yAxis: {
    type: 'value',
    position: 'right',
    splitNumber: 3,
    boundaryGap: ['0%', '10%'],
    splitLine: {
      lineStyle: {
        color: ['#e5e5e9'],
      },
    },
    axisLabel: {
      margin: 4,
      formatter: (value) => numFormatter(value),
      color: '#7c7c93',
    },
  },
  series: [
    {
      data,
      symbol: 'image://' + symbol,
      symbolSize: 16,
      showSymbol: false,
      type: 'line',
      animationDuration: 400,
      zlevel: 3,
    },
  ],
  color: ['#ff761c'],
})

export default {
  name: 'Chart',
  props: {
    width: {
      type: Number,
      default: 752,
    },
    height: {
      type: Number,
      default: 280,
    },
    data: ARRAY_PROPS,
  },
  data() {
    return {
      myChart: null,
    }
  },
  watch: {
    data() {
      this.myChart.setOption(genOption(this.myChart, this.data))
    },
  },
  mounted() {
    const myChart = echarts.init(this.$el, undefined, {
      width: this.width,
      height: this.height,
    })
    if (this.data.length) {
      myChart.setOption(genOption(myChart, this.data))
    }
    this.myChart = myChart
  },
  beforeDestroy() {
    if (this.myChart) {
      this.myChart.dispose()
    }
  },
}
</script>

<style lang="stylus">
@import '~@/css/theme.styl'

.tooltip
  margin-left 4px
  position relative
  > div:before
    content ''
    position absolute
    left -4px
    top 18px
    border-right 4px solid $black
    border-top 4px solid transparent
    border-bottom 4px solid transparent
</style>
