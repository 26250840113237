import Web3 from 'web3'
import { babyJub, eddsa, poseidon, poseidonEncrypt } from 'circom'
import { Scalar, utils } from 'ffjavascript'
import createBlakeHash from 'blake-hash'
import { utils as eutils } from 'ethers'
import Tree from './tree'

export default () => {
  const SNARK_FIELD_SIZE =
    21888242871839275222246405745257275088548364400416034343698204186575808495617n

  const bigInt2Buffer = (i) => {
    let hex = i.toString(16)
    if (hex.length % 2 === 1) {
      hex = '0' + hex
    }
    return Buffer.from(hex, 'hex')
  }

  const genPubKey = (privKey) => {
    return eddsa.prv2pub(bigInt2Buffer(privKey))
  }

  const stringizing = (o, path = []) => {
    if (path.includes(o)) {
      throw new Error('loop nesting!')
    }
    const newPath = [...path, o]

    if (Array.isArray(o)) {
      return o.map((item) => stringizing(item, newPath))
    } else if (typeof o === 'object') {
      const output = {}
      for (const key in o) {
        output[key] = stringizing(o[key], newPath)
      }
      return output
    } else {
      return o.toString()
    }
  }

  const genRandomKey = () => {
    // Prevent modulo bias
    // const lim = BigInt('0x10000000000000000000000000000000000000000000000000000000000000000')
    // const min = (lim - SNARK_FIELD_SIZE) % SNARK_FIELD_SIZE
    const min = 6350874878119819312338956282401532410528162663560392320966563075034087161851n

    let rand
    // eslint-disable-next-line no-constant-condition
    while (true) {
      rand = BigInt(Web3.utils.randomHex(32))

      if (rand >= min) {
        break
      }
    }

    const privKey = rand % SNARK_FIELD_SIZE
    return privKey
  }

  const genKeypair = (pkey) => {
    const privKey = pkey ? BigInt(pkey) : genRandomKey()
    const pubKey = genPubKey(privKey)
    const formatedPrivKey = formatPrivKeyForBabyJub(privKey)

    return { privKey, pubKey, formatedPrivKey }
  }

  const formatPrivKeyForBabyJub = (privKey) => {
    const sBuff = eddsa.pruneBuffer(
      createBlakeHash('blake512').update(bigInt2Buffer(privKey)).digest().slice(0, 32)
    )
    const s = utils.leBuff2int(sBuff)
    return Scalar.shr(s, 3)
  }

  const genEcdhSharedKey = (privKey, pubKey) => {
    const sharedKey = babyJub.mulPointEscalar(pubKey, formatPrivKeyForBabyJub(privKey))
    if (sharedKey[0] === 0n) {
      return [0n, 1n]
    } else {
      return sharedKey
    }
  }

  const genMessageFactory =
    (stateIdx, signPriKey, signPubKey, coordPubKey) =>
    (encPriKey, nonce, voIdx, newVotes, isLastCmd, salt) => {
      if (!salt) {
        // uint56
        salt = BigInt(Web3.utils.randomHex(7))
      }

      const packaged =
        BigInt(nonce) +
        (BigInt(stateIdx) << 32n) +
        (BigInt(voIdx) << 64n) +
        (BigInt(newVotes) << 96n) +
        (BigInt(salt) << 192n)

      let newPubKey = [...signPubKey]
      if (isLastCmd) {
        newPubKey = [0n, 0n]
      }

      const hash = poseidon([packaged, ...newPubKey])
      const signature = eddsa.signPoseidon(bigInt2Buffer(signPriKey), hash)

      const command = [packaged, ...newPubKey, ...signature.R8, signature.S]

      const message = poseidonEncrypt(command, genEcdhSharedKey(encPriKey, coordPubKey), 0n)

      return message
    }

  // Batch generate encrypted commands.
  // output format just like (with commands 1 ~ N):
  // [
  //   [msg_N, msg_N-1, ... msg_3, msg_2, msg_1],
  //   [pubkey_N, pubkey_N-1, ... pubkey_3, pubkey_2, pubkey_1]
  // ]
  // and change the public key at command_N
  const batchGenMessage = (stateIdx, account, coordPubKey, plan) => {
    const genMessage = genMessageFactory(stateIdx, account.privKey, account.pubKey, coordPubKey)

    const messages = []
    const encPubkeys = []
    for (let i = plan.length - 1; i >= 0; i--) {
      const p = plan[i]
      const encAccount = genKeypair()
      const msg = genMessage(encAccount.privKey, i + 1, p[0], p[1], i === plan.length - 1)

      // tuple: { data: uint256[] }
      messages.push([msg])
      // tuple: { x: uint256, y: uint256 }
      encPubkeys.push(encAccount.pubKey)
    }

    return [messages, encPubkeys]
  }

  const rerandomize = (pubKey, ciphertext, randomVal = genRandomKey()) => {
    const d1 = babyJub.addPoint(babyJub.mulPointEscalar(babyJub.Base8, randomVal), ciphertext.c1)

    const d2 = babyJub.addPoint(babyJub.mulPointEscalar(pubKey, randomVal), ciphertext.c2)

    return {
      d1,
      d2,
    }
  }

  const loadJS = (url) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.onload = resolve
      script.src = url

      document.querySelector('head').appendChild(script)
    })
  }

  const genAddKeyProof = async ({
    coordPubKey = [],
    oldKey = null,
    deactivates = [],
    dIdx = 0,
  }) => {
    await loadJS('/snarkjs.min.js')

    const randomVal = genRandomKey()
    const deactivateLeaf = deactivates[dIdx]
    const c1 = [deactivateLeaf[0], deactivateLeaf[1]]
    const c2 = [deactivateLeaf[2], deactivateLeaf[3]]

    const { d1, d2 } = rerandomize(coordPubKey, { c1, c2 }, randomVal)

    const nullifier = poseidon([oldKey.formatedPrivKey, 1444992409218394441042n])

    const tree = new Tree(5, 6, 0n)
    const leaves = deactivates.map((d) => poseidon(d))
    tree.initLeaves(leaves)

    const deactivateRoot = tree.root
    const deactivateLeafPathElements = tree.pathElementOf(dIdx)

    const inputHash =
      BigInt(
        eutils.soliditySha256(
          new Array(7).fill('uint256'),
          stringizing([
            deactivateRoot,
            poseidon(coordPubKey),
            nullifier,
            d1[0],
            d1[1],
            d2[0],
            d2[1],
          ])
        )
      ) % SNARK_FIELD_SIZE

    const input = {
      inputHash,
      coordPubKey,
      deactivateRoot,
      deactivateIndex: dIdx,
      deactivateLeaf: poseidon(deactivateLeaf),
      c1,
      c2,
      randomVal,
      d1,
      d2,
      deactivateLeafPathElements,
      nullifier,
      oldPrivateKey: oldKey.formatedPrivKey,
    }

    // console.log(JSON.stringify(stringizing(input)))

    await new Promise((reslove) => {
      setTimeout(reslove, 1000)
    })

    const res = await window.snarkjs.groth16.fullProve(
      input,
      'circuit.wasm',
      'https://cdn.dorahacks.io/data/circuit_final.zkey'
    )

    const proof = []
    proof.push(...res.proof.pi_a.slice(0, 2))
    proof.push(...res.proof.pi_b[0].reverse())
    proof.push(...res.proof.pi_b[1].reverse())
    proof.push(...res.proof.pi_c.slice(0, 2))

    return stringizing({ proof, d: [...d1, ...d2], nullifier })
  }

  return {
    SNARK_FIELD_SIZE,
    stringizing,
    genKeypair,
    genEcdhSharedKey,
    genMessageFactory,
    batchGenMessage,
    genAddKeyProof,
  }
}
