import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

import modal from './plugins/modal'
import cdn from './plugins/cdn'
import message from 'vue-m-message'

import '@/css/reset.styl'
import '@/css/common.styl'
import '@/components/ui/form/style.styl'
import 'vue-m-message/dist/index.css'

Vue.use(message)
Vue.use(modal)
Vue.use(cdn)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
