export const debounce = (fn, time) => {
  let timer = null
  let hold = false
  const dfn = function (...arg) {
    hold = true
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      hold = false
      fn.call(this, ...arg)
    }, time)
  }
  Object.defineProperty(dfn, 'hold', { get: () => hold })
  return dfn
}

export const STRING_PROPS = {
  type: String,
  default: '',
}

export const NUMBER_PROPS = {
  type: Number,
  default: 0,
}

export const BOOLEAN_PROPS = {
  type: Boolean,
  default: false,
}

export const ARRAY_PROPS = {
  type: Array,
  default: () => [],
}

export const OBJECT_PROPS = {
  type: Object,
  default: () => {},
}

export const numFormatter = (num, decimals = 0) => {
  let n = num || 0
  const unit = ['', 'K', 'M', 'B', 'T']
  let i = 0
  if (Math.abs(n) < 1) {
    return Number(n.toFixed(2)).toString()
  }
  while (Math.abs(n) >= 1e3) {
    n /= 1e3
    i++
  }
  return n.toFixed(decimals) + unit[i]
}

export const amountFormatter = (num) => {
  let [n, d] = (num || 0).toFixed(2).split('.')
  const l = n.length - 1
  n = Array.prototype.reduceRight.call(
    n,
    (s, n, i) => n + (i !== l && (l - i) % 3 === 0 ? ',' : '') + s,
    ''
  )
  return n + '.' + d
}

export const hexFormatter = (hex) => {
  return hex.slice(0, 6) + '…' + hex.slice(-4)
}
