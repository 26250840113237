<template>
  <StakeHome>
    <div>
      <!-- WITHDRAW -->
      <section
        v-if="testmode === 'withdraw' || (!sendTxHash && this.lockEnd && this.lockEnd < Date.now())"
        class="ds-block stake-body"
      >
        <div>
          <h1>Withdraw $DORA</h1>
        </div>

        <hr />

        <div>
          <div class="form">
            <InputText :config="formConfig.currentEnd" :value="lockEndStr" />
            <InputText :config="formConfig.currentStake" :value="locked.toFixed(2)" />
          </div>
          <div class="buttons">
            <div class="main-button" major @click="withdraw">Withdraw</div>
          </div>
        </div>
      </section>

      <!-- STAKE -->
      <section v-else-if="!sendTxHash" class="ds-block stake-body">
        <div>
          <h1>
            {{
              iMode === 'time'
                ? 'Extend lock time'
                : iMode === 'amount'
                ? 'Increase staking amount'
                : this.lockEnd === 0
                ? 'Stake $DORA and participate in Dora Grant DAO governance'
                : 'Increase staking amount or change lock time to get more governance power'
            }}
          </h1>
          <div class="notice">
            <img src="~@/assets/icons/alert_48.svg" />
            <div>
              To learn more about Dora Grant DAO and $DORA tokenomics,
              <a href="https://dorahacks.io/blog/guides/vcdora/" target="_blank">
                <u>check out the guide</u> »
              </a>
            </div>
          </div>
        </div>

        <hr />

        <!-- OLD STAKE -->
        <template v-if="this.lockEnd !== 0">
          <div class="form form-view">
            <InputText :config="formConfig.currentStake" :value="locked.toFixed(2)" />
            <InputText :config="formConfig.currentValue" :value="balance.toFixed(3)" />
            <InputText :config="formConfig.currentEnd" :value="lockEndStr" />
          </div>
          <template v-if="iMode">
            <hr />
            <div class="form" v-if="iMode === 'amount'">
              <InputText :config="formConfig.amount" v-model="inputAmount" />
              <InputText :config="formConfig.totalValue" :value="vcDoraValueStr" />
            </div>
            <div class="form form-view" v-else>
              <InputText :config="formConfig.extendWeek" v-model="inputWeeks" />
              <InputText :config="formConfig.totalValue" :value="vcDoraValueStr" />
              <InputText :config="formConfig.newEnd" :value="endTimeStr" />
            </div>
          </template>
          <div class="buttons">
            <div
              v-if="needApprove"
              class="main-button"
              :disabled="!!approveTimer"
              major
              @click="approve"
            >
              Approve First
            </div>
            <div
              v-if="iMode === 'amount'"
              :disabled="needApprove || !uaAgreed"
              class="main-button"
              major
              key="b1"
              @click="increaseAmount"
            >
              Confirm to Increase
            </div>
            <div
              v-if="iMode === 'time'"
              :disabled="needApprove || !uaAgreed"
              class="main-button"
              major
              key="b2"
              @click="increaseLockTime"
            >
              Confirm to Extend
            </div>
            <div v-if="iMode" class="main-button" key="b3" @click="reset">Cancel</div>
            <div v-if="iMode" class="m-user-agreement">
              <i :selected="uaAgreed" @click="uaAgreed = !uaAgreed" />
              <p>I agree to the <u @click="openUA">Participant Agreement</u></p>
            </div>
            <template v-else>
              <div class="main-button" major key="b4" @click="increaseAmount">Increase Amount</div>
              <div class="main-button" key="b5" @click="increaseLockTime">Extend Lock Time</div>
            </template>
          </div>
        </template>

        <!-- NEW STAKE -->
        <template v-else>
          <div class="form">
            <InputText :config="formConfig.amount" v-model="inputAmount" />
            <InputText :config="formConfig.value" :value="vcDoraValueStr" />
            <InputSelect :config="formConfig.lockTime" v-model="selectTime" />
            <InputText :config="formConfig.endTime" :value="endTimeStr" />
          </div>
          <div class="buttons">
            <div
              v-if="needApprove"
              class="main-button"
              :disabled="!!approveTimer"
              major
              @click="approve"
            >
              Approve First
            </div>
            <div class="main-button" :disabled="needApprove || !uaAgreed" major @click="stake">
              Confirm to Stake
            </div>
            <div class="m-user-agreement">
              <i :selected="uaAgreed" @click="uaAgreed = !uaAgreed" />
              <p>I agree to the <u @click="openUA">Participant Agreement</u></p>
            </div>

            <Loading v-if="approveTimer" />
          </div>
        </template>
      </section>

      <AfterStake v-else :txHash="sendTxHash" @continue="toStake" />
    </div>
  </StakeHome>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Web3 from 'web3'
import { parseTimestamp } from '@/assets/utils/time'
import StakeHome from '@/components/StakeHome'
import InputText from '@/components/ui/form/InputText'
import InputSelect from '@/components/ui/form/InputSelect'
import Loading from '@/components/ui/icons/Loading'
import AfterStake from '@/components/modal/AfterStake'
import UserAgreement from '@/components/modal/UserAgreement'

import config from '@/config.json'

const WEEK = 7 * 86400000

const genFormConfig = () => ({
  amount: {
    label: 'Amount to stake',
    placeholder: 'Amount of $DORA',
    necessary: true,
    type: 'number',
    verify: (n) => {
      if (Number(n) < 0) {
        return 'Stake amount must be positive!'
      }
    },
  },
  value: {
    label: 'Initial vcDORA',
    disabled: true,
  },
  currentValue: {
    label: 'Current vcDORA',
    disabled: true,
  },
  totalValue: {
    label: 'Total vcDORA',
    disabled: true,
  },
  currentStake: {
    label: 'Current $DORA staking',
    disabled: true,
  },
  lockTime: {
    label: 'Lock time',
    placeholder: 'Choose lock time',
    necessary: true,
    options: [
      ['1 week', WEEK],
      ['2 weeks', 2 * WEEK],
      ['1 month', 4 * WEEK],
      ['3 months', 13 * WEEK],
      ['6 months', 26 * WEEK],
      ['1 year', 52 * WEEK],
      ['2 years', 104 * WEEK],
      ['3 years', 156 * WEEK],
      ['4 years', 208 * WEEK],
    ],
  },
  extendWeek: {
    label: 'Weeks to extend',
    placeholder: 'Number of weeks',
    necessary: true,
    type: 'number',
    options: [
      ['1 week', 1],
      ['2 weeks', 2],
      ['1 month (4 weeks)', 4],
      ['3 months (4 weeks)', 13],
      ['6 months (26 weeks)', 26],
      ['1 year (52 weeks)', 52],
      ['2 years (104 weeks)', 104],
      ['3 years (156 weeks)', 156],
      ['4 years (208 weeks)', 208],
    ],
  },
  endTime: {
    label: 'Staking end date',
    disabled: true,
  },
  currentEnd: {
    label: 'Current staking end date',
    disabled: true,
  },
  newEnd: {
    label: 'New end date',
    disabled: true,
  },
})

export default {
  name: 'Stake',
  components: {
    StakeHome,
    InputText,
    InputSelect,
    Loading,
    AfterStake,
  },
  data() {
    const formConfig = genFormConfig()
    formConfig.lockTime.verify = (selected) => {
      const end = Date.now() + selected[1]
      const endEpoch = Math.floor(end / WEEK) * WEEK
      if (endEpoch <= this.lockEnd) {
        return 'You can only choose a lock time that is longer than your current lock time.'
      }
    }
    formConfig.extendWeek.verify = (weeks) => {
      const w = Number(weeks)
      if (w <= 0 || w % 1 !== 0) {
        return 'You must enter a positive integer.'
      }
      const max = 208 - Math.ceil((this.lockEnd - Date.now()) / WEEK)
      if (w > max) {
        return 'The total lock time can’t exceed 4 years (208 weeks).'
      }
    }

    return {
      formConfig,
      iMode: '', // increaseMode

      inputAmount: '',
      inputWeeks: '',
      selectTime: [],

      // If it is not empty, it means that the stake operation is committed.
      sendTxHash: '',
      approveTimer: 0,

      uaAgreed: false,

      // * DEV *
      testmode: '',
    }
  },
  created() {
    window.__test = (mode) => {
      this.testmode = String(mode)
    }
  },
  beforeDestroy() {
    delete window.__test
  },
  computed: {
    ...mapState(['wallet', 'needApprove', 'locked', 'balance', 'lockEnd']),
    ...mapGetters(['account']),
    endTime() {
      if (this.iMode === 'time') {
        const w = Number(this.inputWeeks)
        const max = 208 - Math.ceil((this.lockEnd - Date.now()) / WEEK)
        if (w <= 0 || w % 1 !== 0 || w > max) {
          return 0
        }
        return this.lockEnd + w * WEEK
      }

      if (!this.selectTime.length) {
        // not selected
        return 0
      }
      const time = this.selectTime[1]
      const end = Date.now() + time
      const endEpoch = Math.floor(end / WEEK) * WEEK
      return endEpoch
    },
    endTimeStr() {
      if (this.endTime === 0) {
        return '----/--/--'
      } else {
        return parseTimestamp(this.endTime).slice(0, 10)
      }
    },
    vcDoraValueStr() {
      const increaseAmount = Number(this.inputAmount) / 100

      const now = Date.now()

      let dA = this.locked / 100 || increaseAmount
      let dT = (this.lockEnd || this.endTime) - now
      if (this.iMode === 'amount') {
        dA += increaseAmount
      }
      if (this.iMode === 'time') {
        dT = this.endTime - now
      }
      if (dT <= 0 || !(dA > 0)) {
        // invalid
        return '--'
      }
      const maxtime = 208 * WEEK
      return ((dA / maxtime) * dT).toFixed(3)
    },

    // on chain
    lockEndStr() {
      return parseTimestamp(this.lockEnd).slice(0, 10)
    },
  },
  methods: {
    openUA() {
      this.$modal(
        <UserAgreement
          v-on:confirm={() => {
            this.uaAgreed = true
          }}
        />
      )
    },
    async approve() {
      if (this.approveTimer) return
      if (!this.wallet) {
        this.$roter.push('/')
        return
      }
      const txHash = await this.wallet.sendTransaction({
        from: this.account,
        to: config.dora,
        // approve(address,uint256)
        data: [
          '0x095ea7b3',
          config.vcDora.substring(2).padStart(64, '0').toLowerCase(),
          ''.padStart(64, 'f'),
        ].join(''),
      })
      if (!txHash) return

      // loop check allowance
      this.approveTimer = setTimeout(this.checkAllowance, 4000)
    },

    async stake() {
      if (!this.uaAgreed) return
      if (!(Number(this.inputAmount) > 0)) {
        this.$message.warning('Invalid amount to stake!')
        return
      }
      if (this.endTime <= this.lockEnd) {
        this.$message.warning('Invalid lock time!')
        return
      }

      const txHash = await this.wallet.sendTransaction({
        from: this.account,
        to: config.vcDora,
        // createLock(uint256,uint256)
        data: [
          '0xb52c05fe',
          BigInt(Web3.utils.toWei(this.inputAmount)).toString(16).padStart(64, '0'),
          BigInt(this.endTime / 1000)
            .toString(16)
            .padStart(64, '0'),
        ].join(''),
      })
      if (!txHash) return

      this.sendTxHash = txHash
      this.checkTxReceipt(txHash)
      this.addStakingLog(this.inputAmount, this.endTime, txHash)
    },

    async increaseAmount() {
      if (!this.iMode) {
        this.iMode = 'amount'
        return
      }
      if (!this.uaAgreed) return
      if (!(Number(this.inputAmount) > 0)) {
        this.$message.warning('Invalid amount to stake!')
        return
      }

      const txHash = await this.wallet.sendTransaction({
        from: this.account,
        to: config.vcDora,
        // increaseAmount(uint256)
        data: [
          '0x15456eba',
          BigInt(Web3.utils.toWei(this.inputAmount)).toString(16).padStart(64, '0'),
        ].join(''),
      })
      if (!txHash) return

      this.sendTxHash = txHash
      this.checkTxReceipt(txHash)
      this.addStakingLog(this.locked + Number(this.inputAmount), this.lockEnd, txHash)
    },

    async increaseLockTime() {
      if (!this.iMode) {
        this.iMode = 'time'
        return
      }
      if (!this.uaAgreed) return
      if (this.endTime <= this.lockEnd) {
        this.$message.warning('Invalid lock time!')
        return
      }

      const txHash = await this.wallet.sendTransaction({
        from: this.account,
        to: config.vcDora,
        // increaseUnlockTime(uint256)
        data: [
          '0x7c616fe6',
          BigInt(this.endTime / 1000)
            .toString(16)
            .padStart(64, '0'),
        ].join(''),
      })
      if (!txHash) return

      this.sendTxHash = txHash
      this.checkTxReceipt(txHash)
      this.addStakingLog(this.locked, this.endTime, txHash)
    },

    async withdraw() {
      const txHash = await this.wallet.sendTransaction({
        from: this.account,
        to: config.vcDora,
        // withdraw()
        data: '0x3ccfd60b',
      })
      if (!txHash) return

      this.sendTxHash = txHash
      this.checkTxReceipt(txHash)
    },

    async checkAllowance() {
      await this.$store.dispatch('updateAllowance')
      if (this.needApprove) {
        this.approveTimer = setTimeout(this.checkAllowance, 4000)
      } else {
        this.approveTimer = 0
      }
    },
    checkTxReceipt(txHash) {
      const check = async () => {
        const finish = await this.$store.dispatch('getTxReceipt', txHash)
        if (finish) {
          this.$store.dispatch('updateBalance')
          return
        }
        setTimeout(check, 4000)
      }
      setTimeout(check, 4000)
    },
    addStakingLog(amount, locktime, txHash) {
      this.$store.commit('addPendingStakingLog', {
        amount: Web3.utils.toWei(amount.toString()),
        id: txHash + '-pending',
        locktime,
        ts: Date.now(),
        txHash,
        user: this.account,
      })
    },

    reset() {
      this.iMode = ''
      this.inputAmount = ''
      this.selectTime = []
    },
    toStake() {
      this.reset()
      this.sendTxHash = ''
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/css/theme.styl'

.stake-body
  h1
    margin-bottom 48px
    padding 0 16px
    font-size 24px
    line-height 32px
    font-weight 600
  > div
    max-width 592px
    margin auto
  hr
    margin 24px 0
    height 1px
    background-color $black + 90%

  .notice
    padding 0 16px
    display flex
    justify-content space-between
    align-items center
    > img
      margin-right 16px
      flex 0 0 48px
      height 48px
      width 48px
    > div:last-child
      flex 1 1 auto
      padding 6px 0
      font-size 12px
      line-height 18px
    a
      color $orange

  .form
    display grid
    grid-template-columns 384px 192px
    gap 24px 16px
  .form-view
    grid-template-columns repeat(3, 184px)
  .ui-form-item
    margin 0
  .buttons
    margin-top 48px
    display flex
    align-items center
    gap 16px

.m-user-agreement
  display flex
  font-size 12px
  align-items center
  > i
    margin-right 8px
    flex 0 0 16px
    width 16px
    height 16px
    border-radius 2px
    border solid 1px $black + 80%
    position relative
    transition all .12s
    cursor pointer
    &:before
      content ''
      position absolute
      top 40%
      left 50%
      width 7px
      height 3px
      border-bottom solid 2px $white
      border-left solid 2px $white
      transform translate3d(-50%, -50%, 0) rotate(-45deg) scale(0)
      transition all .2s
    &[selected]
      background-color $orange
      border-color $orange
      &:before
        transform translate3d(-50%, -50%, 0) rotate(-45deg)
  u
    color $orange
    text-decoration underline
    cursor pointer

@media not all and (min-width: 600px)
  .stake-body
    > div
      padding-left: 16px
      padding-right: 16px
    .form
      grid-template-columns 1fr
    .buttons
      flex-wrap: wrap
</style>
